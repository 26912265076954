import React from "react";
import GRAPHIC from "../../Images/identity.svg";
import { AuthLayout } from "../../Components";
import { Button, Form, InputBox, Text } from "@lipihipi/ui-components";
import { InputAdornment } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { postProfile } from "../../Service/Service";
import { MOBILE_OTP } from "../../RouteConstant";
import { personalInfoSchema } from "./validationSchema";
import handleToast, { success } from "../../utils/Toast";

const onboardingData = {
  icon: GRAPHIC,
  primaryText: "Your Identity is,",
  secondaryText: "important for your Identity!",
};

const GeneralInfo = () => {
  const [initialValues, setInitialValues] = React.useState<any>({
    fname: "",
    lname: "",
    designation: "",
    contact_no: "",
  });

  const navigate = useNavigate();

  return (
    <AuthLayout currentStep={2} data={onboardingData}>
      <Text
        sx={{
          mb: 3,
        }}
        variant="h2"
      >
        Please provide contact person details at Company name
      </Text>

      <Form
        initialValues={initialValues}
        validateOnMount={true}
        validationSchema={personalInfoSchema}
        onSubmit={(values: any) => {
          localStorage.setItem("mobile", values.contact_no);
          return new Promise((res: any) => {
            postProfile(
              values,
              (response: any) => {
                handleToast(response.message, success);
                res("");
                navigate(MOBILE_OTP);
              },
              (error: any) => {
                handleToast(error.data.errors.contact_no[0]);
                res("");
              }
            );
          });
        }}
        render={({ handleChange, isValid, submitForm, isSubmitting }: any) => {
          return (
            <>
              <InputBox
                id="fname"
                name="fname"
                fullWidth
                required
                onChange={handleChange}
                sx={{ mb: 3 }}
                placeholder="Enter here..."
                label="First Name"
              />

              <InputBox
                id="lname"
                name="lname"
                fullWidth
                required
                onChange={handleChange}
                sx={{ mb: 3 }}
                placeholder="Enter here..."
                label="Last Name"
              />

              <InputBox
                id="designation"
                name="designation"
                fullWidth
                required
                onChange={handleChange}
                sx={{ mb: 3 }}
                placeholder="Enter here..."
                label="Designation"
              />

              <InputBox
                id="contact_no"
                name="contact_no"
                type="number"
                fullWidth
                required
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Text>+91 - </Text>
                    </InputAdornment>
                  ),
                }}
                sx={{ my: 3 }}
                placeholder="Enter here..."
                label="Mobile number"
              />

              <Button
                loading={isSubmitting}
                disabled={!isValid}
                type="submit"
                sx={{ mt: 5 }}
                onClick={isValid ? submitForm : () => {}}
                fullWidth
                size="large"
              >
                Next
              </Button>
            </>
          );
        }}
      ></Form>
    </AuthLayout>
  );
};

export default GeneralInfo;
