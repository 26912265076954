const vars = {
  primaryFont: "'DM Sans', sans-serif",
  secondaryFont: "'Epilogue', sans-serif",
  primaryColor: '#00C385',
  errorColor: '#ff0000',
  greyLight: '#6E6F70',
  grey: '#bababa',
  greyDark: '#9E9E9E',
};

export default vars;