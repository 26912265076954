export const DASHBOARD_ = "/";
export const DASHBOARD = "/dashboard";
export const LOGIN = "/login";
export const OTP = "/otp";
export const MOBILE_OTP = "/mobile-otp";
export const CREATE_JOB = "/jobs-postings/create-job";
export const EDIT_JOB = "/jobs-postings/edit-job/:id";
export const BENEFITS = "/benefits";
export const EVENTS = "/events";
export const GENERAL_INFO = "/info";
export const COMPANY_INFO = "/company";
export const ADDRESS = "/address";
export const PROFILE = "/profile";
export const ORGANISATION_DETAILS = "/organisation-details";
export const BASIC_DETAILS = "/basic-details";
export const COMPANY_BIO = "/company-profile";
export const INVITED_USER = `/invited-user/:id?`;
export const WALLET = "/wallet";
export const ACHIEVEMENTS = "/achievements";
export const MEDIA = "/gallery";
export const INTERVIEWS = "/interviews";
export const RECRUITER = "/recruiters";
export const ROLES = "/roles";
export const JOBS = "/jobs-postings";
export const LIVE_JOBS = "/jobs-postings/live";
export const DRAFTED_JOBS = "/jobs-postings/drafted";
export const ARCHIVED_JOBS = "/jobs-postings/archived";
export const JOB_DETAILS = "/jobs-postings/:id";
export const SHORTLISTEDCANDIDATES = "/shortlisted-candidates/:id?";
export const APPLIEDCANDIDATES = "/applied-candidates/:id";
export const JOBSEEKERDETAIL = "/job-seeker-detail/:jobId/:userId";
export const FORGOT_PASSWORD = "/password/email";
export const ACCESS_DENIED = "/access-denied"
export const USER_BLOCK = "/user-block"
export const VERIFY_USER = "/user/verify/:token"