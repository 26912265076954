import React, { useEffect, useState } from "react";
import { Box, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { Card, CustomTable } from "@lipihipi/ui-components";

import { SecondaryLayout, Loader, LoadingModal } from "../../Components";
import { getPermissions, savePermissions } from "../../Service";
import handleToast, { success } from "../../utils/Toast";
import { PERMISSION_KEYS } from "./constants";

const createRow = ({
  recruiter,
  permissions,
  onChange,
}: {
  recruiter: Record<string, any>;
  permissions?: Record<string, any>;
  onChange?: (
    event: React.SyntheticEvent<Element, Event>,
    checked: boolean
  ) => void;
}) => {
  const addIdToKey = (key: string) => `${key}${recruiter.id}`;
  return {
    name: `${recruiter.fname} ${recruiter.lname}`,
    recruiter: (
      <FormGroup>
        <FormControlLabel
          control={<Checkbox />}
          onChange={onChange}
          value={JSON.stringify({
            rec_id: [recruiter.id],
            ...permissions,
            [addIdToKey(PERMISSION_KEYS.int_add)]: !(
              permissions?.[addIdToKey(PERMISSION_KEYS.int_add)] &&
              permissions?.[addIdToKey(PERMISSION_KEYS.int_edit)]
            ),
            [addIdToKey(PERMISSION_KEYS.int_edit)]: !(
              permissions?.[addIdToKey(PERMISSION_KEYS.int_add)] &&
              permissions?.[addIdToKey(PERMISSION_KEYS.int_edit)]
            ),
          })}
          label="All"
          key="All"
          checked={
            !!permissions?.[addIdToKey(PERMISSION_KEYS.int_add)] &&
            !!permissions?.[addIdToKey(PERMISSION_KEYS.int_edit)]
          }
        />
        <FormControlLabel
          control={<Checkbox />}
          onChange={onChange}
          label="Add"
          value={JSON.stringify({
            rec_id: [recruiter.id],
            ...permissions,
            [addIdToKey(PERMISSION_KEYS.int_add)]:
              !permissions?.[addIdToKey(PERMISSION_KEYS.int_add)],
          })}
          key="Add"
          checked={!!permissions?.[addIdToKey(PERMISSION_KEYS.int_add)]}
        />
        <FormControlLabel
          control={<Checkbox />}
          label="Edit"
          value={JSON.stringify({
            rec_id: [recruiter.id],
            ...permissions,
            [addIdToKey(PERMISSION_KEYS.int_edit)]:
              !permissions?.[addIdToKey(PERMISSION_KEYS.int_edit)],
          })}
          onChange={onChange}
          key="Edit"
          checked={!!permissions?.[addIdToKey(PERMISSION_KEYS.int_edit)]}
        />
      </FormGroup>
    ),
    jobPosting: (
      <FormGroup>
        <FormControlLabel
          control={<Checkbox />}
          onChange={onChange}
          value={JSON.stringify({
            rec_id: [recruiter.id],
            ...permissions,
            [addIdToKey(PERMISSION_KEYS.job_add)]: !(
              permissions?.[addIdToKey(PERMISSION_KEYS.job_add)] &&
              permissions?.[addIdToKey(PERMISSION_KEYS.job_edit)]
            ),
            [addIdToKey(PERMISSION_KEYS.job_edit)]: !(
              permissions?.[addIdToKey(PERMISSION_KEYS.job_add)] &&
              permissions?.[addIdToKey(PERMISSION_KEYS.job_edit)]
            ),
          })}
          label="All"
          key="All"
          checked={
            !!permissions?.[addIdToKey(PERMISSION_KEYS.job_add)] &&
            !!permissions?.[addIdToKey(PERMISSION_KEYS.job_edit)]
          }
        />
        <FormControlLabel
          control={<Checkbox />}
          onChange={onChange}
          label="Add"
          value={JSON.stringify({
            rec_id: [recruiter.id],
            ...permissions,
            [addIdToKey(PERMISSION_KEYS.job_add)]:
              !permissions?.[addIdToKey(PERMISSION_KEYS.job_add)],
          })}
          key="Add"
          checked={!!permissions?.[addIdToKey(PERMISSION_KEYS.job_add)]}
        />
        <FormControlLabel
          control={<Checkbox />}
          label="Edit"
          onChange={onChange}
          value={JSON.stringify({
            rec_id: [recruiter.id],
            ...permissions,
            [addIdToKey(PERMISSION_KEYS.job_edit)]:
              !permissions?.[addIdToKey(PERMISSION_KEYS.job_edit)],
          })}
          key="Edit"
          checked={!!permissions?.[addIdToKey(PERMISSION_KEYS.job_edit)]}
        />
      </FormGroup>
    ),
  };
};

const TableData = {
  heading: [
    { id: 0, label: "Name" },
    { id: 1, label: "Interview" },
    { id: 2, label: "Job Posting" },
  ],
};

const Roles = () => {
  const [permissionData, setPermissionData] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState(true);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const onChange = (e: any) => {
    const value = e.target.value;
    setUpdateLoading(true);
    savePermissions(
      JSON.parse(value),
      () => {
        handleToast("Permissions Updated", success);
        setRefresh((p) => !p);
        setUpdateLoading(false);
      },
      () => {
        handleToast("Something went wrong", success);
        setUpdateLoading(false);
      }
    );
  };

  const tableData = React.useMemo(() => {
    return loading
      ? []
      : permissionData?.recruiters?.map((recruiter: Record<string, any>) => {
          // Extract User permissions
          let permissions: Record<string, any> ={}
          if(permissionData.permissions !==''){
            permissions = JSON.parse(
              permissionData?.permissions
            ).find((perm: any) => {
              const firstKey = Object.keys(perm)?.[0];
              if (!firstKey) {
                return false;
              }
  
              return `${perm[firstKey]}` === `${recruiter.id}`;
            });

            permissions =
              permissions &&
              Object.entries(permissions).reduce((acc, perm) => {
                acc[`${perm[0]}${perm[1]}`] = true;
                return acc;
              }, {} as Record<string, any>);
  
            }
            return createRow({
              recruiter,
              ...(permissions ? { permissions } : {}),
              onChange,
            });

        });
  }, [loading, permissionData]);

  useEffect(() => {
    setLoading(true);
    getPermissions(
      (data: any) => {
        setPermissionData(data);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  }, [refresh]);

  return (
    <>
      <SecondaryLayout currentActive={6}>
        <Box display="flex" flexDirection="column" rowGap={2}>
          <Card heading="Roles & Permissions" disableAction>
            <Box mx={-4}>
              {loading ? (
                <Loader />
              ) : (
                <CustomTable
                  bodyRowSx={{ "& td": { verticalAlign: "top" } }}
                  data={tableData}
                  heading={TableData.heading}
                />
              )}
            </Box>
          </Card>
        </Box>
      </SecondaryLayout>
      <LoadingModal open={updateLoading} width={250}>
        Updating Permissions
      </LoadingModal>
    </>
  );
};

export default Roles;
