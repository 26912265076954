import { PrimaryList, Card } from "@lipihipi/ui-components";
import employer from "../../../Images/employer.svg";
import moment from "moment";

const EmployerDetails = ({ employments }: any) => {
  return (
    <Card disableAction heading="Employment Details">
      {employments &&
        employments?.map((employment: any) => {
          return (
            <PrimaryList
              icon={<img src={employer} alt="" />}
              chip={{
                color: "warning",
                label: "Not Verified",
              }}
              data={{
                heading: employment?.designation,
                primaryText: employment?.company_name,
                secondaryText: `${moment(employment?.start_date).format(
                  "MMM-Y"
                )} ${
                  employment?.end_date
                    ? `-${moment(new Date(employment?.end_date)).format(
                        "MMM-Y"
                      )}`
                    : " to Present"
                }`,
              }}
              additionalText={employment?.description}
            />
          );
        })}
    </Card>
  );
};

export default EmployerDetails;
