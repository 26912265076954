import React, { useContext, useEffect } from "react";
import { AuthLayout } from "../../../Components";
import GRAPHIC from "../../../Images/personal-details.svg";
import { Button, Form, ImageCropper, InputBox, SelectBox, Text } from "@lipihipi/ui-components";
import { Box, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { getUserMe, updateCompany } from "../../../Service/Service";
import { ASSET_URL, companyStrength } from "../../../constant";
import { uploadPublic } from "../../../Service/FileUploadService";
import { AppContext } from "../../../App";
import handleToast, { success } from "../../../utils/Toast";
import { PROFILE } from "../../../RouteConstant";
import { ComapnySchema } from "./validationSchema";
import { ActionType } from "../../../Reducer";


const colorObj = {
  fontSize: '18px',
  lineHeight: '133%',
  fontWeight: 300,
  color: '#161458',
}

const onboardingData = {
  icon: GRAPHIC,
  content: <Text sx={colorObj} variant="body1">People with <Text sx={{ ...colorObj, fontWeight: 600, }} variant="body2" component='span'>updated profile</Text> have <Text variant="body2" sx={{ ...colorObj, fontWeight: 600, }} component='span'>higher chances</Text> of being recogonised by Mentors and Recruiters.</Text>
};

const EditOrganisationDetails = () => {
  const navigate = useNavigate();
  const { state ,dispatch} = useContext<any>(AppContext);
  const [initialValues, setInitialValues] = React.useState<any>({
    company_name:'',
    company_gst:'',
    company_url:'',
    company_addr:'',
    company_emp_strength:'',
    company_year_establish:'',
    company_logo:''
  });
  useEffect(()=>{
    if(state.user){
      setInitialValues({
        company_name: state?.user?.recruiter_company_info?.company_name,
        company_gst:state?.user?.recruiter_company_info?.company_gst,
        company_url:state?.user?.recruiter_company_info?.company_url,
        company_addr:state?.user?.recruiter_company_info?.company_addr,
        company_emp_strength:state?.user?.recruiter_company_info?.company_emp_strength,
        company_year_establish:state?.user?.recruiter_company_info?.company_year_establish,
        company_logo:state?.user?.recruiter_company_info?.company_logo
      })
    }
  },[state])
  return (
    <AuthLayout isSecondary data={onboardingData}>
      <Form
        initialValues={initialValues}
        validationSchema={ComapnySchema}
        onSubmit={(values: any) => { 
          return new Promise((res: any) => {
            updateCompany(
              values,
              
              (response: any) => {
                getUserMe(
                  (data: any) => {
                    dispatch({
                      type: ActionType.GET_USER_SUCCESS,
                      payload: data.user,
                    });
                    navigate(PROFILE);
                  },
                  (error: any) => {
                    console.log(error);
                  }
                );
                res("");
                handleToast(response.message, success);
              },
              (error: any) => {
                res("");
              }
            );
          });
        }}
        render={({ handleChange,values,isValid,isSubmitting,submitForm }: any) => {
          return (
            <>
              <>
                <Box>
                  <Text
                    sx={{
                      mb: 3,
                    }}
                    variant="h1"
                  >
                    Organisation Details
                  </Text>

                  <InputBox
                    id="company_name"
                    name="company_name"
                    fullWidth
                    sx={{ mb: 3 }}
                    placeholder="Enter here.."
                    label="Organisation/Company Name"
                    onChange={handleChange}
                    required
                  />
                  <InputBox
                    id="company_gst"
                    name="company_gst"
                    fullWidth
                    sx={{ mb: 3 }}
                    placeholder="Enter here.."
                    label="GSTIN No"
                    onChange={handleChange}
                  />
                  <InputBox
                    id="company_url"
                    name="company_url"
                    fullWidth
                    sx={{ mb: 3 }}
                    placeholder="Enter here.."
                    label="Website URL"
                    onChange={handleChange}
                    required
                  />


                  <SelectBox
                    id="company_emp_strength"
                    name="company_emp_strength"
                    sx={{ mb: 3 }}
                    value={values.company_emp_strength}
                    items={companyStrength}
                    label="Number of employees"
                    onChange={handleChange}
                    required
                  />
                  <InputBox
                    id="company_year_establish"
                    name="company_year_establish"
                    fullWidth
                    sx={{ mb: 3 }}
                    placeholder="Enter here.."
                    label="Year of  establishent"
                    onChange={handleChange}
                    required
                  />
                </Box>

                <Box mt={2}>
                  <Text
                    sx={{
                      mb: 3,
                    }}
                    variant="h3"
                  >
                    Logo
                  </Text>

                  <Box display='flex' alignItems='center' columnGap={4}>
                    <ImageCropper
                      id="company_logo"
                      name="company_logo"
                      previewPic={initialValues?.company_logo}
                      label="Upload Company logo"
                      width={250}
                      ratio={1 / 1}
                      accept={['image/jpeg', 'image/jpg', 'image/png']}
                      // uploadFile={uploadPublic}
                      deleteFile={() => { }}
                      required
                      getAssetUrl={ASSET_URL}
                    />
                    <IconButton sx={{
                      '&:hover': {
                        backgroundColor: '#ffeded',
                      }
                    }} color="error">
                      <DeleteOutlineOutlinedIcon sx={{ color: '#d32f2f' }} />
                    </IconButton>
                  </Box>
                </Box>

                <Box mt={4}>
                  <Text
                    sx={{
                      mb: 3,
                    }}
                    variant="h3"
                  >
                    Office address
                  </Text>

                  <InputBox
                    id="company_addr"
                    name="company_addr"
                    fullWidth
                    sx={{ mb: 3 }}
                    multiline
                    maxRows={3}
                    placeholder="Enter here.."
                    label="Address"
                    onChange={handleChange}
                  />
                  {/* 
                  <InputBox
                    id=""
                    name=""
                    fullWidth
                    //sx={{ mb: 3 }}
                    multiline
                    maxRows={3}
                    placeholder="Enter here.."
                    //onChange={() => null}
                    label="Address 2"
                    onChange={handleChange}
                  /> */}
                  {/* 
                  <InputBox
                    id="company_state"
                    name="company_state"
                    fullWidth
                    //sx={{ mb: 3 }}
                    multiline
                    maxRows={3}
                    placeholder="Enter here.."
                    //onChange={() => null}
                    label="State"
                    onChange={handleChange}
                  />

                  <InputBox
                    id="company_dist"
                    name="company_dist"
                    fullWidth
                    //sx={{ mb: 3 }}
                    multiline
                    maxRows={3}
                    placeholder="Enter here.."
                    //onChange={() => null}
                    label="District"
                    onChange={handleChange}
                  />

                  <InputBox
                    id="company_pin"
                    name="company_pin"
                    fullWidth
                    //sx={{ mb: 3 }}
                    multiline
                    maxRows={3}
                    placeholder="Enter here.."
                    //onChange={() => null}
                    label="PIN Code"
                    onChange={handleChange}
                  /> */}
                </Box>
                <Button
                  disabled={!isValid || isSubmitting}
                  onClick={isValid ? submitForm : () => {}}
                  fullWidth
                  sx={{ mt: 5 }}
                  size="large"
                >
                  Save
                </Button>
              </>
            </>
          )
        }}

      >
      </Form>
    </AuthLayout >
  )
};

export default EditOrganisationDetails;