import React, { useContext, useEffect, useState } from "react";
import { Button, InputBox, Form } from "@lipihipi/ui-components";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { register } from "../../Service/Service";
import { useNavigate } from "react-router-dom";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { AppContext } from "../../App";
import { ActionType } from "../../Reducer";
import { FORGOT_PASSWORD, OTP } from "../../RouteConstant";
import { Link } from "react-router-dom";
import { RegisterSchema } from "./validationSchema";

const Register = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { dispatch } = useContext<any>(AppContext);
  const [initialValues, setInitialValues] = React.useState<any>({
    email: "",
    password: "",
    password_confirmation: "",
    company_name: "",
    termsandconditions: false,
    user_type: 3,
  });

  const email = localStorage.getItem("email");
  useEffect(() => {
    setInitialValues({
      email: email,
      password: "",
      password_confirmation: "",
      company_name: "",
      termsandconditions: false,
      user_type: 3,
    });
  }, [email]);
  const onSuccess = (res: any) => {
    console.log(res);
    dispatch({
      type: ActionType.GET_USER_SUCCESS,
      payload: res.user,
    });
    navigate(OTP);
  };

  const OnFailure = (err: any) => {
    console.log(err);
    console.log(err, "after login email failure");
  };

  return (
    <Form
      initialValues={initialValues}
      validationSchema={RegisterSchema}
      onSubmit={(values: any) => {
        return new Promise((rs, rj) => {
          register(
            values,
            (res: any) => {
              onSuccess(res);
              rs("");
            },
            (err: any) => {
              OnFailure(err);
              rj("");
            }
          );
        });
      }}
      render={({ handleChange, isValid, isSubmitting, submitForm }: any) => {
        return (
          <>
            <InputBox
              id="email"
              name="email"
              label="Corporate Email ID"
              autoComplete="on"
              placeholder="Enter here..."
              fullWidth
              required
              onChange={handleChange}
              sx={{ mb: 3 }}
            />
            <InputBox
              id="company_name"
              name="company_name"
              fullWidth
              required
              onChange={handleChange}
              sx={{ mb: 3 }}
              placeholder="Entere here..."
              label="Organisation/Company Name"
            />
            <InputBox
              id="password"
              name="password"
              fullWidth
              type={!showPassword ? "password" : "text"}
              required
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {!showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ mb: 3 }}
              placeholder="Entere here..."
              label="Create Password"
            />
            <InputBox
              type={!showConfirmPassword ? "password" : "text"}
              id="password_confirmation"
              name="password_confirmation"
              fullWidth
              sx={{ mb: 2 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      edge="end"
                    >
                      {!showConfirmPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              required
              onChange={handleChange}
              placeholder="Entere here..."
              label="Re Enter Password"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={handleChange}
                  name="termsandconditions"
                />
              }
              label="Terms and condition"
            />
            <Button
              shape="primary"
              className="ml-3"
              type="submit"
              size="large"
              loading={isSubmitting}
              disabled={!isValid || isSubmitting}
              onClick={isValid ? submitForm : () => {}}
              fullWidth
            >
              Next
            </Button>
          </>
        );
      }}
    />
  );
};

export default Register;
