import { Box, Divider } from "@mui/material";
import { SecondaryLayout } from "../../Components";
import { Card, Text } from "@lipihipi/ui-components";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import { useEffect, useState } from "react";
import { getEvents } from "../../Service";
import NoDataFound from "../../Shared/NoDataFound";
import Loader from "../../Components/Loader";
import { ASSET_URL } from "../../constant";
import employer from '../../Images/employer.svg';
import { Calendar, Clock, IdCard } from "../../Icons";

const Events = () => {
  const [events, setEvents] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getEvents(
      (response: any) => {
        setEvents(Object.values(response.data));
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  }, []);

  return (
    <SecondaryLayout currentActive={2}>
      {!loading && !events?.length ? (
        <NoDataFound />
      ) : (
        <Box>
          <Text variant="h3">
            My Events ({loading ? "..." : events?.length || 0})
          </Text>
          {loading ? (
            <Loader />
          ) : (
            <Box mt={2} display="flex" flexDirection="column" rowGap={1.5}>
              {events?.map((event: any) => {
                return (
                  <>
                    <Card px={2} py={2} disableHeader>
                      <Box display="flex" alignItems='flex-start' gap="24px">
                        <Box
                          width={50}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          height={50}
                          sx={{
                            background: "#FFFFFF",
                            border: "1px solid #F0F0F0",
                            "& img": {
                              maxWidth: "100%",
                              objectFit: "contain",
                              maxHeight: "100%",
                            },
                          }}
                        >
                          <img
                            src={
                              event?.company_id?.company_logo ? 
                              `${ASSET_URL}/${event?.company_id?.company_logo}` :
                              employer
                            }
                            alt=""
                          />
                        </Box>

                        <Box
                          flexGrow={1}
                          display="flex"
                          flexDirection="column"
                          rowGap='9px'
                        >
                          <Text variant="subtitle1" sx={{fontWeight: 600, color: '#161458'}}>{event?.job_title}</Text>
                          <Text variant="subtitle2"
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '8px',
                              fontWeight: 700, fontSize: '13px', color: '#5A6474'
                            }}
                          ><IdCard /> {event?.fname} {event?.lname}</Text>
                          <Box pt={1} display="flex" alignItems='center' gap='10px'>
                            <Text variant="subtitle1" 
                              sx={{
                                color: '#161458', fontSize: '12px',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px',
                              }}>
                              <Calendar />
                              {event?.interview_date}
                            </Text>

                            <Divider sx={{
                              height: '12px',
                              width: '1px',
                              background: '#D3D3D3'
                            }} />

                            <Text variant="subtitle1" 
                              sx={{
                                color: '#161458', fontSize: '12px',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px',
                              }}
                            >
                              <Clock />
                              {event?.interview_time}
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                    </Card>

                    {false && (
                      <Card px={2} py={2} disableHeader>
                        <Box display="flex">
                          <Box
                            pr={1}
                            flexGrow={1}
                            display="flex"
                            flexDirection="column"
                            rowGap={2.5}
                          >
                            <Box>
                              <Text variant="subtitle2">{event?.job_title}</Text>
                              <Text variant="subtitle2">{event?.fname} {event?.lname}</Text>
                              <Text sx={{ mt: 1 }} variant="subtitle2">
                                {`Date & Time: ${event?.interview_date} at ${event?.interview_time}`}
                              </Text>
                            </Box>
                            
                            <Box display="flex" columnGap={2}>
                              <Text
                                sx={{
                                  display: "flex",
                                  alignItems: "end",
                                  columnGap: 1,
                                }}
                              >
                                <CorporateFareIcon />
                                {event?.company_name}
                              </Text>
                            </Box>
                          </Box>
                          
                          {/* <Box
                          width={60}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          height={60}
                          sx={{
                            background: "#FFFFFF",
                            border: "1px solid #F0F0F0",
                            "& img": {
                              maxWidth: "100%",
                              objectFit: "contain",
                              maxHeight: "100%",
                            },
                          }}
                        >
                          <img
                            src={
                              `${ASSET_URL}/${event?.company_id?.company_logo}` ??
                              employer
                            }
                            alt=""
                          />
                        </Box> */}
                        </Box>
                        
                        {/* <Box mt={2.5}>
                        <Text sx={{ color: "#868D90" }} variant="caption">
                          Posted {moment(event?.created_at, "YYYYMMDD").fromNow()}
                        </Text>
                      </Box> */}
                      </Card>
                    )}
                  </>
                );
              })}
            </Box>
          )}
        </Box>
      )}
      ​
    </SecondaryLayout>
  );
};
export default Events;
