import React, { useRef } from "react";
import { Modal } from "../../../Components/Modal";
import { Button, Form, InputBox } from "@lipihipi/ui-components";
import { Grid } from "@mui/material";
import { ScheduleInterviewSchema } from "../../../configs/Schemas";
import { scheduleInterview } from "../../../Service";
import { Datepicker } from "../../../Shared/Datepicker";
import dayjs from "dayjs";
import { TimePicker } from "../../../Shared/TimePicker";
import { SCHEDULE_INTERVIEW_KEYS } from "../../Recruiter/constants";

type Props = {
  candidateData?: Record<string, any>;
  open: boolean;
  handleClose: () => void;
  onSuccess?: () => void;
};

export const ScheduleInterview = ({
  candidateData,
  open,
  handleClose,
  onSuccess,
}: Props) => {
  const formRef = useRef();

  const initialValues = candidateData?.interview_date
    ? {
        [SCHEDULE_INTERVIEW_KEYS.date]: dayjs(
          candidateData?.interview_date,
          "DD/MM/YYYY"
        ),
        [SCHEDULE_INTERVIEW_KEYS.time]: dayjs(
          candidateData?.interview_time,
          "HH:mm A"
        ),
        [SCHEDULE_INTERVIEW_KEYS.description]: candidateData?.description,
        [SCHEDULE_INTERVIEW_KEYS.venue]: candidateData?.interview_venue,
      }
    : {};

  const handleSubmit = (values: Record<string, any>) => {
    const date = dayjs(values.date).format("MM/DD/YYYY");
    const time = dayjs(values.time).format("HH:mm A");

    return new Promise((rs, rj) => {
      if (candidateData) {
        const { job_id, user_id } = candidateData;

        scheduleInterview(
          { ...values, date, time, job_content: job_id, user_id },
          () => {
            handleClose();
            onSuccess?.();
            rs("");
          },
          () => {
            rs("");
          }
        );
      }
    });
  };

  return (
    <>
      <Modal open={open} handleClose={handleClose} title={"Schedule Interview"}>
        <Form
          formRef={formRef}
          initialValues={initialValues}
          validationSchema={ScheduleInterviewSchema}
          onSubmit={handleSubmit}
          render={({
            handleChange,
            submitForm,
            isValid,
            isSubmitting,
            setValues,
            values,
          }: any) => (
            <Grid
              container
              direction={"column"}
              spacing={5}
              sx={{ paddingBottom: "1rem" }}
            >
              <Grid container item direction={"column"} spacing={3}>
                <Grid item>
                  <Datepicker
                    value={initialValues[SCHEDULE_INTERVIEW_KEYS.date]}
                    views={["day", "month", "year"]}
                    name="date"
                    label="Select Date"
                    onChange={(value: any) =>
                      setValues({ ...values, date: value })
                    }
                  />
                  {/* <InputBox
                    autoComplete="off"
                    name={"date"}
                    fullWidth
                    required
                    placeholder="Select Date"
                    label="Select Date"
                    onChange={handleChange}
                  /> */}
                </Grid>
                <Grid item>
                  <TimePicker
                    name="time"
                    value={initialValues[SCHEDULE_INTERVIEW_KEYS.time]}
                    required
                    label="Select Time"
                    onChange={(value: any) =>
                      setValues({ ...values, time: value })
                    }
                  />
                  {/* <InputBox
                    name={"time"}
                    fullWidth
                    required
                    placeholder="Select Time"
                    label="Select Time"
                    onChange={handleChange}
                  /> */}
                </Grid>
                <Grid item>
                  <InputBox
                    name={"venue"}
                    fullWidth
                    required
                    placeholder="Enter Venue"
                    label="Enter Venue"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item>
                  <InputBox
                    name={"description"}
                    fullWidth
                    multiline
                    minRows={3}
                    placeholder="Enter Description (Optional)"
                    label="Enter Description"
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid item container justifyContent={"center"}>
                <Button
                  {...(isValid ? { onClick: submitForm } : {})}
                  disabled={!isValid || isSubmitting}
                  loading={isSubmitting}
                  variant="contained"
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          )}
        />
      </Modal>
    </>
  );
};

export default ScheduleInterview;
