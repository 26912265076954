import { PrimaryList, Card } from "@lipihipi/ui-components";
import subtract from "../../../Images/subtract.svg";

const EducationDetails = ({ educations }: any) => {
  return (
    <Card disableAction heading="Education Details">
      {educations?.map((education: any) => {
        return (
          <>
            <PrimaryList
              icon={<img src={subtract} alt="" />}
              chip={{
                color: "warning",
                label: "Not Verified",
              }}
              data={{
                heading: education?.education_type
                  ? education?.education_type.value
                  : education?.graduation_type?.value,
                primaryText: education?.university,
                secondaryText: `${education?.passing_from_year} ${
                  education?.passing_to_year
                    ? `to ${education?.passing_to_year}`
                    : ""
                }`,
              }}
            />
          </>
        );
      })}
    </Card>
  );
};

export default EducationDetails;
