import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Text } from "@lipihipi/ui-components";
import { Box } from "@mui/material";

import {
  SecondaryLayout,
  JobCard,
  Loader,
  LoadingModal,
} from "../../../Components";
import {
  changeJobStatus,
  getJobDetails,
  getPostedJobs,
  postNewJob,
  updateJob,
} from "../../../Service";
import { EDIT_JOB, JOBS } from "../../../RouteConstant";
import NoDataFound from "../../../Shared/NoDataFound";
import {
  createItemsFromObjects,
  replaceParamsInUrl,
} from "../../../utils/common";
import handleToast, { success, error } from "../../../utils/Toast";
import { JOB_API_NAME_MAPPING } from "../constants";

const MENU_ITEMS = {
  edit: "Edit",
  block: "Block",
  publish: "Publish",
};

const DraftedJobs = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const editClickHandler = (data: Record<string, any>) => {
    navigate(replaceParamsInUrl(EDIT_JOB, { id: data.id }));
    window.scrollTo(0, 0);
  };

  const blockClickHandler = (data: Record<string, any>) => {
    setUpdateLoading(true);
    changeJobStatus(
      {
        id: data.id,
        status: false,
      },
      (res: any) => {
        handleToast(res.message, success);
        setUpdateLoading(false);
        setRefresh((p) => !p);
      },
      () => {
        handleToast("Some error occurred while changing status!", error);
        setUpdateLoading(false);
      }
    );
  };

  const publishClickHandler = (data: Record<string, any>) => {
    setUpdateLoading(true);
    getJobDetails(
      data.id,
      (res: any) => {
        const {
          graduation_candidate,
          post_graduation_candidate,
          doctor_candidate,
          ...job
        } = Object.keys(res?.job || {}).reduce((acc, key) => {
          const keyInMap =
            JOB_API_NAME_MAPPING[key as keyof typeof JOB_API_NAME_MAPPING];
          acc[keyInMap || key] = `${res.job[key] !== null ? res.job[key] : ""}`;
          return acc;
        }, {} as Record<string, any>);

        job.job_categories = job.job_categories.split(",");

        updateJob(
          { ...job, draft: 0 },
          () => {
            handleToast("Job published successfully", success);
            setUpdateLoading(false);
            setRefresh((p) => !p);
          },
          () => {
            handleToast("Some error occurred while publishing job!", error);
            setUpdateLoading(false);
          }
        );
      },
      () => {}
    );
  };

  const menuClickMap: Partial<Record<keyof typeof MENU_ITEMS, Function>> = {
    edit: editClickHandler,
    block: blockClickHandler,
    publish: publishClickHandler,
  };

  useEffect(() => {
    setLoading(true);
    getPostedJobs(
      { draft: true },
      (data: Record<string, any>) => {
        setData(data?.jobs);
        setLoading(false);
      },
      () => setLoading(false)
    );
  }, [refresh]);

  return (
    <>
      <SecondaryLayout currentActive={1}>
        <Box>
          <Box
            mb={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text variant="h3">
              Drafted Jobs ({loading ? "..." : data.length || 0})
            </Text>
          </Box>
          <Box mt={2} display="flex" flexDirection="column" rowGap={1.5}>
            {loading ? (
              <Loader />
            ) : data.length ? (
              data.map((job: Record<string, any>) => {
                return (
                  <JobCard
                    key={job.id}
                    job={job}
                    onClick={() => navigate(`${JOBS}/${job.id}`)}
                    menuItems={createItemsFromObjects(MENU_ITEMS)}
                    onMenuClick={(item, data) =>
                      menuClickMap[item.value as keyof typeof MENU_ITEMS]?.(
                        data
                      )
                    }
                  />
                );
              })
            ) : (
              <NoDataFound />
            )}
          </Box>
        </Box>
      </SecondaryLayout>
      <LoadingModal open={updateLoading} width={250}>
        Updating Job Status
      </LoadingModal>
    </>
  );
};

export default DraftedJobs;
