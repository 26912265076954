import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { SecondaryLayout } from "../../Components";
import Loader from "../../Components/Loader";
import JobDetailCard from "./JobDetailCard";
import { getJobDetail } from "../../Service";

export const JobDetails = () => {
  const [jobData, setJobData] = useState<any>(null);
  const { id: jobId } = useParams();

  useEffect(() => {
    if (jobId) {
      getJobDetail(
        jobId,
        (response: any) => {
          window.scrollTo(0, 0);
          setJobData(response?.job);
        },
        () => {}
      );
    }
  }, [jobId]);

  return (
    <SecondaryLayout currentActive={1}>
      {!jobData ? <Loader /> : <JobDetailCard jobData={jobData} />}
    </SecondaryLayout>
  );
};

export default JobDetails;
