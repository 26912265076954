import React, { useRef } from "react";
import { Modal } from "../../../Components/Modal";
import { Button, Form, InputBox } from "@lipihipi/ui-components";
import { Grid } from "@mui/material";
import { InviteUserSchema } from "../../../configs/Schemas";
import { inviteUser } from "../../../Service";

type Props = {
  interviewDetails?: Record<string, any>;
  open: boolean;
  handleClose: () => void;
  onSuccess?: (message?: string) => void;
  onError?: (message?: string[]) => void;
};

export const InviteUser = ({
  interviewDetails,
  open,
  handleClose,
  onSuccess,
  onError,
}: Props) => {
  const formRef = useRef();

  const handleSubmit = (values: Record<string, any>) => {
    return new Promise((rs) => {
      inviteUser(
        { ...values, interviewId: interviewDetails?.id },
        () => {
          handleClose();
          onSuccess?.("User Invited successfully");
          rs("");
        },
        (err: any) => {
          const messages: string[] = Object.values(
            err?.data?.errors || { invalide: err?.data?.message } || {
                error: "Error while Inviting user",
              }
          );
          onError?.(messages.map((item) => item[0]));
          rs("");
        }
      );
    });
  };

  return (
    <>
      <Modal
        open={open}
        handleClose={handleClose}
        title={"Share Interview Link"}
      >
        <Form
          initialValues={{}}
          formRef={formRef}
          validationSchema={InviteUserSchema}
          onSubmit={handleSubmit}
          render={({
            handleChange,
            submitForm,
            isValid,
            isSubmitting,
          }: any) => (
            <Grid
              container
              direction={"column"}
              spacing={5}
              sx={{ paddingBottom: "1rem" }}
            >
              <Grid container item direction={"column"} spacing={3}>
                <Grid item>
                  <InputBox
                    required
                    autoComplete="off"
                    name={"fname"}
                    fullWidth
                    placeholder="Enter First Name"
                    label="First Name"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item>
                  <InputBox
                    required
                    name={"lname"}
                    fullWidth
                    placeholder="Enter Last Name"
                    label="Last Name"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item>
                  <InputBox
                    name={"email"}
                    required
                    fullWidth
                    placeholder="Enter Email Address"
                    label="Email Id"
                    onChange={handleChange}
                  />
                </Grid>
                {}
                <Grid item>
                  <InputBox
                    required
                    name={"mobile"}
                    fullWidth
                    placeholder="Enter Mobile No."
                    label="Mobile No."
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid item container justifyContent={"center"}>
                <Button
                  {...(isValid ? { onClick: submitForm } : {})}
                  disabled={!isValid || isSubmitting}
                  loading={isSubmitting}
                  variant="contained"
                >
                  Invite
                </Button>
              </Grid>
            </Grid>
          )}
        />
      </Modal>
    </>
  );
};

export default InviteUser;
