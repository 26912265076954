// import client from "client";
import { BASE_URL } from "../constant";
import handleToast, { success } from "../utils/Toast";
import { client } from "./client";

export const login = (data: any, onSuccess: any, onFailure: any) => {
  client
    .post(`${BASE_URL}/login`, data)
    .then(({ data }) => {
      localStorage.setItem("token", data.token);
      onSuccess(data);
    })
    .catch((err) => {
      console.log(err);
      onFailure(err);
    });
};
export const register = (data: any, onSuccess: any, onFailure: any) => {
  client
    .post(`${BASE_URL}/employer/register`, data)
    .then(({ data }) => {
      localStorage.setItem("token", data.token);
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};
export const verifyUser = (data: any, onSuccess: any, onFailure: any) => {
  client
    .post(`${BASE_URL}/email/verify`, data)
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};
export const UserToken = () => localStorage.getItem("token");

export const config = () => ({
  headers: {
    Authorization: `Bearer ${UserToken()}`,
  },
});

export const sendEmailOtp = (
  onSuccess: any,
  onFailure: any
  // params: any = {}
) => {
  client
    .get(`${BASE_URL}/send-email-otp`, {
      ...config(),
    })
    .then(({ data }) => {
      onSuccess(data);
      handleToast(data.message, success);
    })
    .catch((err) => {
      onFailure(err);
      handleToast(err.message);
    });
};

export const verifyEmailOtp = (onSuccess: any, params: any = {}) => {
  client
    .post(`${BASE_URL}/verify-email-otp`, params, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      handleToast(err.message);
    });
};

export const postProfile = (data: any, onSuccess: any, onFailure: any) => {
  client
    .post(`${BASE_URL}/employer/update-profile`, data, config())
    .then(({ data }: any) => {
      onSuccess(data);
    })
    .catch((err: any) => {
      onFailure(err);
    });
};

export const updateCompany = (data: any, onSuccess: any, onFailure: any) => {
  client
    .post(`${BASE_URL}/employer/update-company`, data, config())
    .then(({ data }: any) => {
      onSuccess(data);
    })
    .catch((err: any) => {
      onFailure(err);
    });
};

export const sendMobileOtp = (
  onSuccess: any,
  onFailure: any
  // params: any = {}
) => {
  client
    .get(`${BASE_URL}/send-mobile-otp`, {
      ...config(),
    })
    .then(({ data }) => {
      handleToast(data.message, success);
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
      handleToast(err.message);
    });
};
export const resendMobileOtp = () =>
  // onSuccess: any,
  // onFailure: any,
  // params: any = {}
  {
    client
      .get(`${BASE_URL}/resend-mobile-otp`, {
        ...config(),
      })
      .then(({ data }) => {
        handleToast(data.message, success);
        // onSuccess(data);
      })
      .catch((err) => {
        // onFailure(err);
        handleToast(err.message);
      });
  };
export const resendEmailOtp = () =>
  // onSuccess: any,
  // onFailure: any,
  // params: any = {}
  {
    client
      .get(`${BASE_URL}/resend-email-otp`, {
        ...config(),
      })
      .then(({ data }) => {
        handleToast(data.message, success);
        // onSuccess(data);
      })
      .catch((err) => {
        // onFailure(err);
        handleToast(err.message);
      });
  };

export const verifyMobileOtp = (
  onSuccess: any,
  onFailure: any,
  params: any = {}
) => {
  client
    .post(`${BASE_URL}/verify-mobile-otp`, params, config())
    .then(({ data }) => {
      handleToast(data.message.success);
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
      handleToast(err.message);
    });
};

export const getDashboard = (onSuccess: any) => {
  client
    .get(`${BASE_URL}/employer/dashboard`, {
      params: { date_filter: 0 },
      ...config(),
    })
    .then(({ data }: any) => {
      onSuccess(data);
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const getWallet = (onSuccess: any, onError?: any) => {
  client
    .get(`${BASE_URL}/employer/wallet`, {
      ...config(),
    })
    .then(({ data }: any) => {
      onSuccess(data);
    })
    .catch((err: any) => {
      onError?.(err);
    });
};

export const getProfile = (
  onSuccess: any,
  onFailure: any
  // params: any = {}
) => {
  client
    .get(`${BASE_URL}/employer/profile`, {
      ...config(),
    })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const getShortlistedCandidates = (
  onSuccess: any,
  onFailure: any,
  id?: any
) => {
  client
    .get(`${BASE_URL}/employer/shortlist/${id || ""}`, {
      ...config(),
    })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const getCandidateInfo = (
  data: Record<string, any>,
  onSuccess: any,
  onFailure: any
) => {
  client
    .get(`${BASE_URL}/employer/candidate-info`, { ...config(), params: data })
    .then(({ data }: any) => {
      onSuccess(data);
    })
    .catch((err: any) => {
      onFailure(err);
    });
};

export const checkUserWallet = (
  data: Record<string, any>,
  onSuccess: any,
  onFailure: any
) => {
  client
    .get(`${BASE_URL}/employer/check-user-wallet`, {
      ...config(),
      params: data,
    })
    .then(({ data }: any) => {
      onSuccess(data);
    })
    .catch((err: any) => {
      onFailure(err);
    });
};

export const getAppliedCandidates = (
  id: any,
  onSuccess: any,
  onFailure: any
) => {
  client
    .get(`${BASE_URL}/employer/job-post/applied/${id}`, {
      ...config(),
    })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const shortlistCandidate = (
  data: Record<string, any>,
  onSuccess: any,
  onFailure: any
) => {
  client
    .post(`${BASE_URL}/employer/job-post/shortlist-profile`, data, config())
    .then(({ data }: any) => {
      onSuccess(data);
    })
    .catch((err: any) => {
      onFailure(err);
    });
};

export const notFitCandidate = (
  data: Record<string, any>,
  onSuccess: any,
  onFailure: any
) => {
  client
    .post(`${BASE_URL}/employer/job-post/not-fit`, data, config())
    .then(({ data }: any) => {
      onSuccess(data);
    })
    .catch((err: any) => {
      onFailure(err);
    });
};

export const deleteShortlistCandidate = (
  data: Record<string, any>,
  onSuccess: any,
  onFailure: any
) => {
  client
    .post(`${BASE_URL}/employer/job-post/not-fit`, data, config())
    .then(({ data }: any) => {
      onSuccess(data);
    })
    .catch((err: any) => {
      onFailure(err);
    });
};

export const scheduleInterview = (
  data: Record<string, any>,
  onSuccess: any,
  onFailure: any
) => {
  client
    .post(`${BASE_URL}/employer/job-post/schedule-interview`, data, config())
    .then(({ data }: any) => {
      onSuccess(data);
    })
    .catch((err: any) => {
      onFailure(err);
    });
};

export const getRecruiters = (onSuccess: any, onFailure: any) => {
  client
    .get(`${BASE_URL}/employer/recruiter`, {
      ...config(),
    })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const getPermissions = (onSuccess: any, onFailure: any) => {
  client
    .get(`${BASE_URL}/employer/permission`, {
      ...config(),
    })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const savePermissions = (
  data: Record<string, any>,
  onSuccess: any,
  onFailure: any
) => {
  client
    .post(`${BASE_URL}/employer/permission`, data, config())
    .then(({ data }: any) => {
      onSuccess(data);
    })
    .catch((err: any) => {
      onFailure(err);
    });
};

export const getEvents = (onSuccess: any, onFailure: any) => {
  client
    .get(`${BASE_URL}/employer/events`, {
      ...config(),
    })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const addRecruiter = (
  data: Record<string, any>,
  onSuccess: any,
  onFailure: any
) => {
  client
    .post(`${BASE_URL}/employer/recruiter`, data, config())
    .then(({ data }: any) => {
      onSuccess(data);
    })
    .catch((err: any) => {
      onFailure(err);
    });
};

export const updateRecruiter = (
  data: Record<string, any>,
  onSuccess: any,
  onFailure: any
) => {
  client
    .patch(`${BASE_URL}/employer/recruiter`, data, config())
    .then(({ data }: any) => {
      onSuccess(data);
    })
    .catch((err: any) => {
      onFailure(err);
    });
};

export const AddMoneyToRecruiter = (
  data: Record<string, any>,
  onSuccess: any,
  onFailure: any
) => {
  client
    .post(`${BASE_URL}/employer/recruiter/wallet/add`, data, config())
    .then(({ data }: any) => {
      onSuccess(data);
    })
    .catch((err: any) => {
      onFailure(err);
    });
};

export const withdrawMoneyRecruiter = (
  data: Record<string, any>,
  onSuccess: any,
  onFailure: any
) => {
  client
    .post(`${BASE_URL}/employer/recruiter/wallet/withdraw`, data, config())
    .then(({ data }: any) => {
      onSuccess(data);
    })
    .catch((err: any) => {
      onFailure(err);
    });
};

export const udpateRecruiter = (
  data: Record<string, any>,
  onSuccess: any,
  onFailure: any
) => {
  client
    .patch(`${BASE_URL}/employer/recruiter`, data, config())
    .then(({ data }: any) => {
      onSuccess(data);
    })
    .catch((err: any) => {
      onFailure(err);
    });
};

export const changeRecruiterStatus = (
  data: Record<string, any>,
  onSuccess: any,
  onFailure: any
) => {
  client
    .patch(`${BASE_URL}/employer/recruiter/status`, data, config())
    .then(({ data }: any) => {
      onSuccess(data);
    })
    .catch((err: any) => {
      onFailure(err);
    });
};

export const getAIInterviews = (onSuccess: any, onFailure: any) => {
  client
    .get(`${BASE_URL}/employer/ai-interviews`, {
      ...config(),
    })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const getInterviewsDetails = (
  slug: string,
  onSuccess: any,
  onFailure: any
) => {
  client
    .get(`${BASE_URL}/employer/ai-interviews/detail?slug=${slug}`, {
      ...config(),
    })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const getInterviewLink = (
  slug: string,
  onSuccess: any,
  onFailure: any
) => {
  client
    .get(`${BASE_URL}/interview/attempt?slug=${slug}`, {
      ...config(),
    })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const getInterviewReport = (
  slug: string,
  onSuccess: any,
  onFailure: any
) => {
  client
    .get(`${BASE_URL}/interview/report?slug=${slug}`, {
      ...config(),
    })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const getUserInterviewReport = (
  data: Record<string, any>,
  onSuccess: any,
  onFailure: any
) => {
  client
    .get(`${BASE_URL}/interview/report`, {
      ...config(),
      params: data,
    })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const inviteUser = (data: any, onSuccess: any, onFailure: any) => {
  client
    .post(`${BASE_URL}/employer/ai-interviews/share-link`, data, config())
    .then(({ data }: any) => {
      onSuccess(data);
    })
    .catch((err: any) => {
      onFailure(err);
    });
};

export const getBenefits = (onSuccess: any) => {
  client
    .get(`${BASE_URL}/employer/benefits`, {
      ...config(),
    })
    .then(({ data }: any) => {
      onSuccess(data);
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const addBenefits = (data: any, onSuccess: any, onFailure: any) => {
  client
    .post(`${BASE_URL}/employer/benefits`, data, config())
    .then(({ data }: any) => {
      onSuccess(data);
    })
    .catch((err: any) => {
      onFailure(err);
    });
};

export const deleteBenefits = (data: any, onSuccess: any, onFailure: any) => {
  client
    .delete(`${BASE_URL}/employer/benefits`, {
      data,
      ...config(),
    })
    .then(({ data }: any) => {
      onSuccess(data);
    })
    .catch((err: any) => {
      onFailure(err);
    });
};

export const getAchievementList = (onSuccess: any) => {
  client
    .get(`${BASE_URL}/employer/achievement`, {
      ...config(),
    })
    .then(({ data }: any) => {
      onSuccess(data);
    })
    .catch((err: any) => {
      console.log(err);
    });
};
export const getAchievement = (id: any, onSuccess: any) => {
  client
    .get(`${BASE_URL}/employer/achievement/${id}`, {
      ...config(),
    })
    .then(({ data }: any) => {
      onSuccess(data);
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const deleteAchievement = (
  data: any,
  onSuccess: any,
  onFailure: any
) => {
  client
    .delete(`${BASE_URL}/employer/achievement/${data}`, {
      ...config(),
    })
    .then(({ data }: any) => {
      onSuccess(data);
    })
    .catch((err: any) => {
      onFailure(err);
    });
};

export const addAchievement = (data: any, onSuccess: any) => {
  client
    .post(`${BASE_URL}/employer/achievement`, data, config())
    .then(({ data }: any) => {
      onSuccess(data);
    })
    .catch((err: any) => {
      console.log(err);
    });
};
export const updateAchievement = (data: any, onSuccess: any) => {
  client
    .patch(`${BASE_URL}/employer/achievement/${data.id}`, data, config())
    .then(({ data }: any) => {
      onSuccess(data);
    })
    .catch((err: any) => {
      console.log(err);
    });
};
export const changeStatusAchievement = (data: any, onSuccess: any) => {
  client
    .post(`${BASE_URL}/employer/achievement/status-update`, data, config())
    .then(({ data }: any) => {
      onSuccess(data);
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const getCompanyBio = (onSuccess: any) => {
  client
    .get(`${BASE_URL}/employer/company-bio`, {
      ...config(),
    })
    .then(({ data }: any) => {
      onSuccess(data);
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const saveCompanyBio = (data: any, onSuccess: any, onFailure: any) => {
  client
    .post(`${BASE_URL}/employer/company-bio`, data, config())
    .then(({ data }: any) => {
      onSuccess(data);
    })
    .catch((err: any) => {
      onFailure(err);
    });
};

export const getMedia = (onSuccess: any) => {
  client
    .get(`${BASE_URL}/employer/media-gallery`, {
      ...config(),
    })
    .then(({ data }: any) => {
      onSuccess(data);
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const deleteMedia = (data: any, onSuccess: any) => {
  client
    .delete(`${BASE_URL}/employer/media-gallery/${data}`, {
      ...config(),
    })
    .then(({ data }: any) => {
      onSuccess(data);
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const addMedia = (data: any, onSuccess: any) => {
  client
    .post(`${BASE_URL}/employer/media-gallery`, data, config())
    .then(({ data }: any) => {
      onSuccess(data);
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const savePassword = (data: any, onSuccess: any) => {
  client
    .post(`${BASE_URL}/password/reset`, data, config())
    .then(({ data }: any) => {
      onSuccess();
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const getUserMe = (
  onSuccess: any,
  onFailure: any
  // params: any = {}
) => {
  client
    .get(`${BASE_URL}/employer/me`, {
      ...config(),
    })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const createUser = (data: any, onSuccess: any, onFailure: any) => {
  client
    .post(`${BASE_URL}/user`, data, config())
    .then(({ data }) => {
      // localStorage.setItem("token", data.data.token);
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const getPostedJobs = (data: any, onSuccess: any, onFailure: any) => {
  client
    .get(`${BASE_URL}/employer/job-post/list`, {
      params: data,
      ...config(),
    })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const postNewJob = (data: any, onSuccess: any, onFailure: any) => {
  client
    .post(`${BASE_URL}/employer/job-post`, data, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const updateJob = (data: any, onSuccess: any, onFailure: any) => {
  client
    .patch(`${BASE_URL}/employer/job-post`, data, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const changeJobStatus = (data: any, onSuccess: any, onFailure: any) => {
  client
    .patch(`${BASE_URL}/employer/job-post/status`, data, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const getJobDetails = (
  jobId: string | number,
  onSuccess: any,
  onFailure: any
) => {
  client
    .get(`${BASE_URL}/employer/job-post/${jobId}`, {
      ...config(),
    })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const getOptions = (data: any) => {
  return client.get(`${BASE_URL}/option`, { ...config(), params: data });
};

export const getPaymentOptions = (
  data: any,
  onSuccess: any,
  onFailure: any
) => {
  client
    .post(`${BASE_URL}/payment/options`, data, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const forgotPassword = (data: any, onSuccess: any, onFailure: any) => {
  client
    .post(`${BASE_URL}/password/email`, data, config())
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      onFailure(error);
    });
};

export const getJobDetail = (
  jobId: string | number,
  onSuccess: any,
  onFailure: any
) => {
  client
    .get(`${BASE_URL}/employer/job-post/detail/${jobId}`, {
      ...config(),
    })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};
