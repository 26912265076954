import { Card, Text } from "@lipihipi/ui-components";
import { Box } from "@mui/material";
import { NotVerifiedIcon, VerifiedIcon } from "../../Icons";

const ProfilePicture = ({ profile }: any) => {
  return (
    <>
      <Card disableHeader sx={{ p: 0 }}>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-start"
          paddingTop={1.5}
          paddingRight={1.5}
          sx={{
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            backgroundColor: "#EBF4FF",
            height: 224,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "top center",
            backgroundImage: `url(${profile?.cover_image})`,
          }}
        ></Box>
        <Box px={1.5} pt={3} columnGap={2} display="flex" pb={1.5}>
          <Box
            mt={-9}
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
            sx={{
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: `url(${profile?.image})`,
              height: "181px",
              border: "6px solid #FFFFFF",
              backgroundColor: "#EBF4FF",
              borderRadius: "50%",
              width: "181px",
            }}
          ></Box>
          <Box>
            <Box mb={1} columnGap={1.5} display="flex" alignItems="center">
              <Text variant="h1">
                {profile?.user?.fname} {profile?.user?.lname}
              </Text>
              <Box display="flex" columnGap={0.5} alignItems="center">
                {parseInt(profile?.user?.verified_user) ? (
                  <>
                    <VerifiedIcon />
                    <Text sx={{ color: "#00C385" }}>Verified</Text>
                  </>
                ) : (
                  <>
                    <NotVerifiedIcon />
                    <Text sx={{ color: "#EB5757" }}>Not Verified</Text>
                  </>
                )}
              </Box>
            </Box>
            <Text sx={{ mt: 0.5 }}>
              {profile?.designation ? `${profile?.designation} at` : ""}{" "}
              {profile?.company_name ? profile?.company_name : ""}
            </Text>
            <Text sx={{ mt: 0.5 }}>{profile?.state}</Text>
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default ProfilePicture;
