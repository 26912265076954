import { SecondaryLayout } from "../../Components";
import { Button, Card, InputBox, Form } from "@lipihipi/ui-components";
import React from "react";
import { getCompanyBio, saveCompanyBio } from "../../Service/Service";
import { DASHBOARD } from "../../RouteConstant";
import handleToast, { success } from "../../utils/Toast";
import { useNavigate } from "react-router-dom";
import { ComapnyBioSchema } from "./Organisation/validationSchema";

const CompanyBio = () => {
  const [initialValues, setInitialValues] = React.useState<any>({
    company_bio: "",
  });
  const navigate = useNavigate();
  React.useEffect(() => {
    getCompanyBio(onSuccess);
  }, []);

  const onSuccess = (res: any) => {
    setInitialValues({ company_bio: res.data });
  };

  return (
    <SecondaryLayout currentActive={9}>
      <Card heading="Company Bio" disableAction>
        <Form
          initialValues={initialValues}
          validationSchema={ComapnyBioSchema}
          onSubmit={(values: any) => {
            return new Promise((res: any) => {
              saveCompanyBio(
                values,
                (response: any) => {
                  navigate(DASHBOARD);
                  res("");
                  handleToast(response.message, success);
                },
                (error: any) => {
                  res("");
                }
              );
            });
          }}
          render={({
            handleChange,
            isValid,
            isSubmitting,
            submitForm,
          }: any) => {
            return (
              <>
                <InputBox
                  name="company_bio"
                  fullWidth
                  sx={{ mb: 3 }}
                  multiline
                  minRows={5}
                  // helperText={`${wordcount}/1000 words`}
                  placeholder="Enter here.."
                  onChange={handleChange}
                  label="Enter Company Bio"
                />
                <Button
                  disabled={!isValid || isSubmitting}
                  onClick={isValid ? submitForm : () => {}}
                  sx={{ mb: 3 }}
                  centered
                >
                  Save
                </Button>
              </>
            );
          }}
        />
      </Card>
    </SecondaryLayout>
  );
};

export default CompanyBio;
