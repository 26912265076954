import React, {
  ChangeEvent,
  InputHTMLAttributes,
  KeyboardEvent,
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AuthLayout, Loader } from "../../Components";
import GRAPHIC from "../../Images/otp.svg";
import { useNavigate } from "react-router-dom";
import { Button, Text } from "@lipihipi/ui-components";
import vars from "../../theme/variables";
import { Box } from "@mui/material";
import { DASHBOARD, GENERAL_INFO } from "../../RouteConstant";
import { AppContext } from "../../App";
import {
  getUserMe,
  resendMobileOtp,
  sendMobileOtp,
  verifyMobileOtp,
} from "../../Service";
import { onFailure } from "../../utils/helper";
import { ActionType } from "../../Reducer";
import { EditIcon } from "../../Icons";

const onboardingData = {
  icon: GRAPHIC,
  primaryText: "Security,",
  secondaryText: "we never compromise on!",
};
function usePrevious<T>(value?: T) {
  const ref = useRef<T>();
  useEffect(() => {
    //On load component sendotp and remove senOtp button.write another useeffctech()
    ref.current = value;
  }, [value]);

  return ref.current;
}

interface OTPInputProps extends InputHTMLAttributes<HTMLInputElement> {
  focus?: boolean;
}
const SingleOTPInput = memo((props: OTPInputProps) => {
  const { focus, autoFocus, ...rest } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const prevFocus = usePrevious(!!focus);
  useEffect(() => {
    if (inputRef.current) {
      if (focus && autoFocus) {
        inputRef.current.focus();
      }
      if (focus && autoFocus && focus !== prevFocus) {
        inputRef.current.focus();
        inputRef.current.select();
        // inputRef.current.select();
      }
    }
  }, [autoFocus, focus, prevFocus]);

  return (
    <input
      type="number"
      ref={inputRef}
      className="otp-input"
      maxLength={1}
      {...rest}
    />
  );
});
const length = 4;
const seconds = 60;
const MobileOTP = () => {
  const [otpValues, setOTPValues] = useState(Array<string>(length).fill(""));
  const [OTP, setOTP] = useState("");
  const [otpInput, setOtpInput] = useState(false);
  const [activeInput, setActiveInput] = useState(0);
  const { state, dispatch } = useContext<any>(AppContext);
  const [item, setItem] = useState<any>("");
  const [error, setError] = useState(false);
  const [timeLeft, setTimeLeft] = useState(seconds);
  const intervalRef = useRef<any>();
  const navigate = useNavigate();

  useEffect(() => {
    const user: any = localStorage.getItem("mobile");
    if (user) {
      setItem(user);
    } else {
      setItem(state?.user?.contact_no);
    }
  }, [state]);

  useEffect(() => {
    if (!timeLeft) return;
    const intervalId = setInterval(() => setTimeLeft(timeLeft - 1), 1000);
    intervalRef.current = intervalId;
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  useEffect(() => {
    sendMobileOtp(onSuccessSend, (err: any) => {
      setError(true);
    });
    //New code:-//On load component sendotp and remove senOtp button.write another useeffctech()
  }, []);
  const onSuccessSend = (res: any) => {
    if (res.success) {
      setOtpInput(true);
      console.log("SMS SEND succesfully");
    } else {
      console.log("ERROR IN SMS SEND");
    }
  };
  const btnStyle = {
    p: 0,
    minWidth: "1px",
    fontSize: 16,
    height: "1.5",
    lineHeight: "1",
    color: "#3047EC",
    borderRadius: 0,
    fontFamily: vars.secondaryFont,
    "&:hover": {
      textDecoration: "underline",
      backgroundColor: "transparent",
    },
  };
  // Helper to return OTP from inputs
  const handleOtpChange = (otp: string[]) => {
    const otpValue = otp.join("");
    setOTP(otpValue);
  };
  const changeCodeAtFocus = useCallback(
    (str: string) => {
      const updatedOTPValues = [...otpValues];
      updatedOTPValues[activeInput] = str[0] || "";
      setOTPValues(updatedOTPValues);
      handleOtpChange(updatedOTPValues);
    },
    [activeInput, handleOtpChange, otpValues]
  );

  // Focus `inputIndex` input
  const focusInput = useCallback(
    (inputIndex: number) => {
      const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
      setActiveInput(selectedIndex);
    },
    [length]
  );

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1);
  }, [activeInput, focusInput]);

  // Handle onFocus input
  const handleOnFocus = useCallback(
    (index: number) => () => {
      focusInput(index);
    },
    [focusInput]
  );
  // Handle onChange value for each input
  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.currentTarget;
      const val = !value || /\d/.test(value) ? value : "";
      if (!val) {
        e.preventDefault();
        return;
      }
      changeCodeAtFocus(val);
      focusNextInput();
    },
    [changeCodeAtFocus, focusNextInput]
  );

  // Hanlde onBlur input
  const onBlur = useCallback(() => {
    setActiveInput(-1);
  }, []);
  const handleOnKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case "Backspace":
        case "Delete": {
          e.preventDefault();
          if (otpValues[activeInput]) {
            changeCodeAtFocus("");
          } else {
            focusPrevInput();
          }
          break;
        }
        case "ArrowLeft": {
          e.preventDefault();
          focusPrevInput();
          break;
        }
        case "ArrowRight": {
          e.preventDefault();
          focusNextInput();
          break;
        }
        case " ": {
          e.preventDefault();
          break;
        }
        default:
          break;
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, otpValues]
  );
  const onSuccessVerify = (res: any) => {
    getUserMe(
      (data: any) => {
        dispatch({
          type: ActionType.GET_USER_SUCCESS,
          payload: data.user,
        });
        navigate(DASHBOARD);
      },
      (error: any) => {
        console.log(error);
      }
    );
  };
  const resendOtp = () => {
    setTimeLeft(seconds);
    resendMobileOtp();
  };
  const onSubmitOTP = useCallback(() => {
    const payload: any = new FormData();
    payload.append("otp", OTP);
    verifyMobileOtp(onSuccessVerify, onFailure, payload);
  }, [OTP]);
  return (
    <AuthLayout currentStep={2} data={onboardingData} >
      <Text variant="h2">Please enter Verification Code sent on {item} here <EditIcon onClick={()=>navigate(GENERAL_INFO)} style={{cursor: 'pointer'}} /></Text>
      {!otpInput ? (
        <Box mt={2}>
          <Loader />
        </Box>
      ) : (
        <>
          <Box display="flex" textAlign="center" columnGap={2} mt={10} mb={2}>
            {Array(4)
              .fill("")
              .map((_, index) => (
                <SingleOTPInput
                  key={`SingleInput-${index}`}
                  focus={activeInput === index}
                  autoFocus={true}
                  value={otpValues && otpValues[index]}
                  onFocus={handleOnFocus(index)}
                  onChange={handleOnChange}
                  onKeyDown={handleOnKeyDown}
                  onBlur={onBlur}
                />
              ))}
          </Box>
          {!!timeLeft && <p>{`00:${timeLeft} seconds`}</p>}
          {(!timeLeft || error) && (
            <Text>
              Didn’t receive Verification Code?{" "}
              <Button
                disableRipple
                sx={btnStyle}
                variant="text"
                onClick={resendOtp}
              >
                Resend
              </Button>
            </Text>
          )}
        </>
      )}
      <Button
        disabled={OTP.length !== length}
        type="submit"
        sx={{ mt: 7.5 }}
        fullWidth
        size="large"
        onClick={onSubmitOTP}
      >
        Next
      </Button>
    </AuthLayout>
  );
};

export default MobileOTP;
