export const RECRUITER_ACTION_KEYS = {
  edit: "edit_recruiter",
  addMoney: "add_money",
  withdrawMoney: "withdraw_monry",
  block: "bloc_recruiter",
};

export const SCHEDULE_INTERVIEW_KEYS = {
  date: "date",
  description: "description",
  job_content: "job_content",
  time: "time",
  venue: "venue",
};
