import { useNavigate, useParams } from "react-router-dom";
import handleToast, { error, success } from "../../../utils/Toast";
import { JOBS } from "../../../RouteConstant";
import { CreateJob } from "../Create";
import { useEffect, useState } from "react";
import { getJobDetails } from "../../../Service";
import { JOB_API_NAME_MAPPING, MULTI_SELECT_FIELDS } from "../constants";
import Loader from "../../../Components/Loader";
import { SecondaryLayout } from "../../../Components";

export const EditJobWrapper = () => {
  const navigate = useNavigate();
  const { id: jobId } = useParams();

  const [jobPost, setJobPost] = useState<Record<string, any> | null>(null);
  const [loading, setLoading] = useState(true);

  const onSuccess = () => {
    handleToast("Job Updated Successfully", success);
    navigate(JOBS);
  };

  const onError = () => {
    handleToast("Error while Updating job", error);
  };

  useEffect(() => {
    if (jobId) {
      setLoading(true);
      getJobDetails(
        jobId,
        (res: any) => {
          const job = Object.keys(res?.job || {}).reduce((acc, key) => {
            const keyInMap =
              JOB_API_NAME_MAPPING[key as keyof typeof JOB_API_NAME_MAPPING];

            if (MULTI_SELECT_FIELDS.includes(key)) {
              acc[keyInMap || key] = res.job[key] !== null ? res.job[key]?.split(",").filter(Boolean) : []

              return acc;
            }

            acc[keyInMap || key] = `${
              res.job[key] !== null ? res.job[key] : ""
            }`;
            return acc;
          }, {} as Record<string, any>);
          setJobPost(job);
          setLoading(false);
        },
        () => {
          handleToast("Error while fetching job details", error);
          setLoading(false);
        }
      );
    }
  }, [jobId]);

  return (
    <>
      <SecondaryLayout currentActive={1}>
        {loading ? (
          <Loader />
        ) : (
          <CreateJob
            title="Edit job post"
            onSuccess={onSuccess}
            onError={onError}
            jobPost={jobPost}
          />
        )}
      </SecondaryLayout>
    </>
  );
};

export default EditJobWrapper;
