import React, { useContext, useEffect } from "react";
import { AuthLayout } from "../../../Components";
import GRAPHIC from "../../../Images/personal-details.svg";
import {
  Button,
  CheckBox,
  Form,
  InputBox,
  Text,
} from "@lipihipi/ui-components";
import { Box, InputAdornment } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getUserMe, postProfile } from "../../../Service/Service";
import { BasicDetailSchema } from "./validationSchema";
import { AppContext } from "../../../App";
import handleToast, { success } from "../../../utils/Toast";
import { PROFILE } from "../../../RouteConstant";
import { ActionType } from "../../../Reducer";

const colorObj = {
  fontSize: "18px",
  lineHeight: "133%",
  fontWeight: 300,
  color: "#161458",
};

const onboardingData = {
  icon: GRAPHIC,
  content: (
    <Text sx={colorObj} variant="body1">
      People with{" "}
      <Text
        sx={{ ...colorObj, fontWeight: 600 }}
        variant="body2"
        component="span"
      >
        updated profile
      </Text>{" "}
      have{" "}
      <Text
        variant="body2"
        sx={{ ...colorObj, fontWeight: 600 }}
        component="span"
      >
        higher chances
      </Text>{" "}
      of being recogonised by Mentors and Recruiters.
    </Text>
  ),
};

const EditBasicDetails = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext<any>(AppContext);
  const [initialValues, setInitialValues] = React.useState({
    fname: "",
    lname: "",
    designation: "",
    contact_no: "",
    is_hide_phone: false,
  });
  useEffect(() => {
    if (state.user) {
      setInitialValues({
        fname: state.user.fname,
        lname: state.user.lname,
        designation: state?.user?.recruiter_company_info?.emp_designation,
        contact_no: state.user.contact_no,
        is_hide_phone: state.user.is_hide_phone,
      });
    }
  }, [state]);
  return (
    <AuthLayout isSecondary data={onboardingData}>
      <Form
        initialValues={initialValues}
        validationSchema={BasicDetailSchema}
        onSubmit={(values: any) => {
          return new Promise((res: any) => {
            const payload: any = {};
            payload.fname = values.fname;
            payload.lname = values.lname;
            payload.designation = values.designation;
            payload.is_hide_phone = values.is_hide_phone;
            postProfile(
              payload,
              (response: any) => {
                getUserMe(
                  (data: any) => {
                    dispatch({
                      type: ActionType.GET_USER_SUCCESS,
                      payload: data.user,
                    });
                    navigate(PROFILE);
                  },
                  (error: any) => {
                    console.log(error);
                  }
                );
                res("");
                handleToast(response.message, success);
              },
              (error: any) => {
                res("");
              }
            );
          });
        }}
        render={({ handleChange, isValid, isSubmitting, submitForm }: any) => {
          return (
            <>
              <Box>
                <Text
                  sx={{
                    mb: 3,
                  }}
                  variant="h1"
                >
                  Contact Person Details
                </Text>

                <InputBox
                  name="fname"
                  fullWidth
                  onChange={handleChange}
                  sx={{ mb: 3 }}
                  placeholder="Enter here.."
                  label="First Name"
                  required
                />

                <InputBox
                  name="lname"
                  fullWidth
                  onChange={handleChange}
                  sx={{ mb: 3 }}
                  placeholder="Enter here.."
                  label="Last Name"
                  required
                />

                <InputBox
                  name="contact_no"
                  fullWidth
                  disabled
                  onChange={handleChange}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Text>+91 - </Text>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ mb: 3 }}
                  placeholder="Entere here..."
                  label="Mobile number"
                />
                <CheckBox
                  onChange={handleChange}
                  name={"is_hide_phone"}
                  label="Hide my number"
                />
                <InputBox
                  name="designation"
                  fullWidth
                  onChange={handleChange}
                  sx={{ mb: 3 }}
                  placeholder="Enter here.."
                  label="Designation"
                  required
                />
              </Box>
              <Button
                disabled={!isValid || isSubmitting}
                onClick={isValid ? submitForm : () => {}}
                fullWidth
                sx={{ mt: 5 }}
                size="large"
              >
                Save
              </Button>
            </>
          );
        }}
      ></Form>
    </AuthLayout>
  );
};

export default EditBasicDetails;
