import { SelectBox, Form, Button } from "@lipihipi/ui-components";
import { Box, Grid } from "@mui/material";
import { JOB_POST_FORM_NAMES } from "../../constants";

export const DesiredProfile = ({
  onSubmit,
  inputOptions,
  initialValues,
}: any) => (
  <>
    <Form
      {...(initialValues ? { initialValues } : {})}
      onSubmit={onSubmit}
      render={({ isValid, submitForm, isSubmitting }: any) => (
        <>
          <Box>
            <Grid container columnSpacing={2.5}>
              <Grid item xs={12} lg={4}>
                <SelectBox
                  sx={{ mb: 3 }}
                  name={JOB_POST_FORM_NAMES.graduation_candidate}
                  isMulti
                  disabled={
                    !inputOptions[JOB_POST_FORM_NAMES.graduation_candidate]
                      ?.length
                  }
                  items={inputOptions[JOB_POST_FORM_NAMES.graduation_candidate]}
                  label="Graduation"
                  required
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <SelectBox
                  sx={{ mb: 3 }}
                  isMulti
                  name={JOB_POST_FORM_NAMES.post_graduation_candidate}
                  disabled={
                    !inputOptions[JOB_POST_FORM_NAMES.post_graduation_candidate]
                      ?.length
                  }
                  items={
                    inputOptions[JOB_POST_FORM_NAMES.post_graduation_candidate]
                  }
                  label="Post Graduation"
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <SelectBox
                  sx={{ mb: 3 }}
                  freeSolo
                  isMulti
                  name={JOB_POST_FORM_NAMES.doctor_candidate}
                  disabled={
                    !inputOptions[JOB_POST_FORM_NAMES.doctor_candidate]?.length
                  }
                  items={inputOptions[JOB_POST_FORM_NAMES.doctor_candidate]}
                  label="Specify Doctorate"
                />
              </Grid>
              {/* <Grid item xs={12} lg={12}>
                <SelectBox
                  sx={{ mb: 3 }}
                  name={JOB_POST_FORM_NAMES.specialization}
                  valueModifier={(value: Array<string> | string) =>
                    Array.isArray(value) ? value.join(",") : value
                  }
                  isMulti
                  freeSolo
                  disabled={
                    !inputOptions[JOB_POST_FORM_NAMES.specialization]?.length
                  }
                  label="Specialization"
                />
              </Grid> */}
            </Grid>
          </Box>

          <Button disabled={!isValid || isSubmitting} onClick={submitForm}>
            Save
          </Button>
        </>
      )}
    />
  </>
);
