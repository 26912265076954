import React from "react";
import { useNavigate } from "react-router";

import { Box } from "@mui/material";
import { Text, Button } from "@lipihipi/ui-components";
import IMAGE from "../../Images/block.jpg";
import { ActionType } from "../../Reducer";
import { LOGIN } from "../../RouteConstant";
import { AppContext } from "../../App";

const AccessDenied = () => {
  const { dispatch } = React.useContext<any>(AppContext);
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <img height={350} src={IMAGE} alt="" />
      <Text variant="h2">Access Denied/Wrong URL</Text>

      <Button
        variant="contained"
        onClick={() => {
          dispatch({
            type: ActionType.LOGOUT,
            payload: "",
          });
          navigate(LOGIN);
        }}
      >
        Go Back
      </Button>
    </Box>
  );
};

export default AccessDenied;
