import { Box, Container } from "@mui/material";
import { Logo } from "../../Icons";
import { DropDown } from "../DropDown";

export const Header = ({ setCollapsed, collapsed, isSecondary = false }: any) => {
  const classes = {
    root: {
      background: '#fff',
      position: 'sticky',
      top: 0,
      left: 0,
      height: '67px',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)'
    },

    pointer: {
      cursor: 'pointer'
    }
  }
  return (
    <Box
      display='flex'
      alignItems='center'
      
      sx={classes.root}
    >
      <Container
        sx={{
          display:'flex',
          alignItems:'center'
        }}
        maxWidth="xl"
      >
        <Logo style={classes.pointer} />

        {!isSecondary && (
          <Box
            display='flex'
            ml='auto'
            alignItems='center'
          >
            <DropDown />
          </Box>
        )}
      </Container>
    </Box>
  );
};
