export const LOGIN = "LOGIN";
export const OTP = "OTP";
export const MOBILE_OTP = "MOBILE_OTP";
export const CREATE_JOB = "CREATE_JOB";
export const BENEFITS = "BENEFITS";
export const EVENTS = "EVENTS";
export const PASSWORD = "PASSWORD";
export const GENERAL_INFO = "GENERAL_INFO";
export const COMPANY_INFO = "COMPANY_INFO";
export const ADDRESS = "ADDRESS";
export const PROFILE = "PROFILE";
export const ORGANISATION_DETAILS = "ORGANISATION_DETAILS";
export const BASIC_DETAILS = "BASIC_DETAILS";
export const COMPANY_BIO = "COMPANY_BIO";
export const DASHBOARD = "DASHBOARD";
export const WALLET = "WALLET";
export const ACHIEVEMENTS = "ACHIEVEMENTS";
export const MEDIA = "MEDIA";
export const INTERVIEWS = "INTERVIEWS";
export const RECRUITER = "RECRUITER";
export const ROLES = "ROLES";
export const JOBS = "JOBS";
export const JOB_DETAILS = "JOBS_DETAILS";
export const SHORTLISTEDCANDIDATES = "SHORTLISTEDCANDIDATES";
export const JOBSEEKERDETAIL = "JOBSEEKERDETAIL";

export const OPTIONS_ID = {
  IdType: 1,
  FieldOfStudy: 2,
  JobType: 3,
  SpecifyGraduation: 4,
  SpecifyPostGraduation: 5,
  SpecifyDoctorate: 6,
  JobCategory: 7,
  Currency: 8,
  PreferredShiftTime: 9,
  Industry: 10,
  FunctionalArea: 11,
  EducationType: 12,
  Board: 13,
  SchoolMedium: 14,
  Graduation: 15,
  Specialisation: 16,
  GradingSystem: 17,
  CourseType: 18,
  Hobbies: 19,
  NoticePeriod: 20,
};

export const CONTAINER_MAX_WIDTH = "lg";

let SITE_URL = "https://company.staging.lipijobs.com";
let BASE_URL = "https://test.api.lipijobs.com/api/v1";
// let BASE_URL = "http://localhost:8000/api/v1";
let ASSET_URL = "https://lipijobs-stage.s3.ap-south-1.amazonaws.com";

if (process.env.REACT_APP_ENV === "prod") {
  BASE_URL = "https://lipijobs.com/api/v1";
  ASSET_URL = "https://lipijobs-prod.s3.ap-south-1.amazonaws.com";
  SITE_URL = "https://company.lipijobs.com"
}


export const SMALL_PAGE_SIZE = 10;

export const USER_TYPES = {
  superadmin: 1,
  subrecruiter: 4,
  crossplatform: 2,
  employer: 3,
};

export const companyStrength = [
  { value: "0 to 20", text: "0 to 20" },
  { value: "20 to 50", text: "20 to 50" },
  { value: "50 to 100", text: "50 to 100" },
  { value: "100 to 200", text: "100 to 200" },
  { value: "200 to 400", text: "200 to 400" },
  { value: "400 to 800", text: "400 to 800" },
  { value: "800", text: "800+" },
];

export { BASE_URL,ASSET_URL,SITE_URL };

export enum userLogin {
  Init='Init',
  Login='Login',
  Register='Register',
}