import { ToastContent, ToastOptions, toast } from "react-toastify";
export const { error, success, info, warning }: any = toast;

const handleToast = (
  content: string,
  type?: (content: ToastContent<{}>, options?: ToastOptions) => void,
  options?: ToastOptions
) => {
  type = type || error;
  type?.(content, options || {});
};

export default handleToast;
