import React, { useRef } from "react";
import { Modal } from "../../../Components/Modal";
import { Button, Form, InputBox } from "@lipihipi/ui-components";
import { Grid } from "@mui/material";
import { WithdrawRecruiterMoneySchema } from "../../../configs/Schemas";
import { withdrawMoneyRecruiter } from "../../../Service";

type Props = {
  open: boolean;
  handleClose: () => void;
  onSuccess?: (message: string) => void;
  recruiterData: Record<string, any>;
};

export const WithdrawRecruiterMoney = ({
  open,
  handleClose,
  onSuccess,
  recruiterData,
}: Props) => {
  const formRef = useRef();

  const handleSubmit = (values: Record<string, any>) => {
    const { id: record_id } = recruiterData;

    return new Promise((rs, rj) => {
      withdrawMoneyRecruiter(
        { ...values, record_id },
        (data: any) => {
          handleClose();
          onSuccess?.("Money withdrew successfully");
          rs("");
        },
        () => {
          rs("");
        }
      );
    });
  };

  return (
    <>
      <Modal
        open={open}
        handleClose={handleClose}
        title={"Withdraw balance amount"}
      >
        <Form
          formRef={formRef}
          validationSchema={WithdrawRecruiterMoneySchema}
          onSubmit={handleSubmit}
          render={({
            handleChange,
            submitForm,
            isValid,
            isSubmitting,
          }: any) => (
            <Grid
              container
              direction={"column"}
              spacing={5}
              sx={{ paddingBottom: "1rem" }}
            >
              <Grid container item direction={"column"} spacing={3}>
                <Grid item>
                  <InputBox
                    required
                    name={"amount_withdraw"}
                    fullWidth
                    placeholder="Amount"
                    label="Withdraw Amount"
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid item container justifyContent={"center"}>
                <Button
                  {...(isValid ? { onClick: submitForm } : {})}
                  disabled={!isValid || isSubmitting}
                  loading={isSubmitting}
                  variant="contained"
                >
                  Withdraw
                </Button>
              </Grid>
            </Grid>
          )}
        />
      </Modal>
    </>
  );
};

export default WithdrawRecruiterMoney;
