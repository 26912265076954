import React from "react";
import { AuthLayout } from "../../Components";
import GRAPHIC from "../../Images/builder.svg";
import { Button, InputBox, Text, Form } from "@lipihipi/ui-components";
import { ForgotPasswordSchema } from "./validationSchema";
import { forgotPassword } from "../../Service";
import handleToast, { success } from "../../utils/Toast";

const onboardingData = {
  icon: GRAPHIC,
  primaryText: "You are a real Nation Builder",
  secondaryText: "Showing Job Seeker a new path to success",
};

const ForgotPassword = () => {
  return (
    <AuthLayout currentStep={0} data={onboardingData}>
      <Text
        sx={{
          mb: 3,
        }}
        variant="h2"
      >
        Please enter the email address. A password reset link will be sent to
        you
      </Text>

      <Form
        validationSchema={ForgotPasswordSchema}
        validateOnMount={true}
        onSubmit={(values: any) => {
          return new Promise((res: any) => {
            forgotPassword(
              values,
              (response: any) => {
                handleToast(response?.message, success);
                res("");
              },
              (error: any) => {
                handleToast(error.data.message);
                res("");
              }
            );
          });
        }}
        render={({ handleChange, isValid, isSubmitting, submitForm }: any) => {
          return (
            <>
              <InputBox
                name="email"
                label="Email ID"
                autoComplete="on"
                placeholder="Enter here..."
                fullWidth
                required
                onChange={handleChange}
                sx={{ mb: 3 }}
              />
              <Button
                disabled={!isValid}
                loading={isSubmitting}
                onClick={isValid ? submitForm : () => {}}
                shape="primary"
                className="ml-3"
                size="large"
                fullWidth
              >
                Submit
              </Button>
            </>
          );
        }}
      />
    </AuthLayout>
  );
};

export default ForgotPassword;
