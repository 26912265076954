import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker as MuiTimePicker } from "@mui/x-date-pickers/TimePicker";
import vars from "../../theme/variables";
import createPalette from "@mui/material/styles/createPalette";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";

const { primaryFont, primaryColor, errorColor, greyLight, grey, greyDark } =
  vars;

interface ITimepicker {
  name?: string;
  label?: string;
  views?: any; //readonly DateView[]
  sx?: Object;
  value?: any;
  onChange?: any;
  required?: boolean;
  slotProps?: any; //DatePickerSlotsComponentsProps<unknown>
}

const customPalette = createPalette({
  primary: {
    main: primaryColor,
  },
  grey: {
    "100": greyLight,
    "200": grey,
    "300": greyDark,
  },
  error: {
    main: errorColor,
  },
});

const theme = createTheme({
  typography: {
    fontFamily: primaryFont,
  },
  components: {
    MuiList: {
      styleOverrides: {
        root: {
          "&:hover": {
            scrollbarWidth: "thin",
          },
          "::-webkit-scrollbar": {
            display: "none",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "183%",
          color: customPalette.grey[300],
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {},
        root: {
          "&:hover:not(.Mui-disabled, .Mui-error)::before": {
            borderColor: customPalette.grey[200],
            borderWidth: 1,
          },

          "&:after": {
            borderColor: customPalette.primary.main,
          },
          "&:before": {
            borderColor: customPalette.grey[200],
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: customPalette.error.main,
        },
        root: {
          fontWeight: 400,
          fontSize: "1rem",
          color: customPalette?.grey[100],

          "&.Mui-focused": {
            color: customPalette?.grey[100],
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: (themeParam) => ({
        ".MuiPickersDay-root.Mui-selected": {
          backgroundColor: `${customPalette.primary.main} !important`,
        },
      }),
    },
  },
});

const classes = {
  root: {
    width: "100%",
  },
};

export const TimePicker = ({
  onChange,
  value : enternalValue,
  label,
  required,
  name,
  sx,
}: ITimepicker) => {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState<Dayjs | null>(enternalValue);

  React.useEffect(() => {
    setValue(value);
  }, [value]);

  const handleChange = (value: Dayjs | null) => {
    setValue(value);
    onChange?.(value);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MuiTimePicker
          label={label}
          value={value}
          sx={{
            ...classes.root,
            sx,
          }}
          open={open}
          onClose={() => setOpen(false)}
          slotProps={{
            textField: {
              variant: "standard",
              required,
              name: name,
              onClick: () => setOpen(true),
            },
          }}
          onChange={handleChange}
        />
      </ThemeProvider>
    </LocalizationProvider>
  );
};
