import React from "react";

import {
  Button,
  Form,
  InputBox,
  RichTextEditor,
} from "@lipihipi/ui-components";
import { JOB_POST_FORM_NAMES } from "../../constants";
import { Grid } from "@mui/material";

export const MustHave = ({ onSubmit, initialValues }: any) => (
  <>
    <Form
      {...(initialValues ? { initialValues } : {})}
      onSubmit={onSubmit}
      render={({ isValid, submitForm, isSubmitting }: any) => (
        <>
          <Grid container direction={"column"} gap={2}>
            <Grid item>
              <RichTextEditor
                id={`must-have`}
                label={"Must Have"}
                name={JOB_POST_FORM_NAMES.must_have}
                maxHeight={600}
                uploadFile={(params, config) =>
                  new Promise((res) => {
                    res({} as any);
                  })
                }
                getAssetUrl={""}
              />
            </Grid>
            <Grid item>
              <Button disabled={!isValid || isSubmitting} onClick={submitForm}>
                Save
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    />
  </>
);

export default MustHave;
