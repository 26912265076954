import React, { ReactNode } from "react";
import { Box, Container, Grid } from "@mui/material";
import { Back, Header } from "@lipihipi/ui-components";
import { useNavigate } from "react-router-dom";
import { Logo } from "../../Icons";
import { DropDown } from "../../Pages/Profile/Dropdown";
import { CONTAINER_MAX_WIDTH } from "../../constant";
import { Sidebar } from "../Sidebar";

interface ISecondaryLayout {
  children: ReactNode;
  back?: boolean;
  hideRefer?: boolean;
  hideAdditionalInfo?: boolean;
  hideWallet?: boolean;
  rightPanel?: ReactNode;
  leftPanel?: ReactNode;
  currentActive:number;
}

export const SecondaryLayout: React.FC<ISecondaryLayout> = ({
  children,
  back = false,
  rightPanel,
  leftPanel,
  currentActive
}) => {
  const navigate = useNavigate();
  return (
    <>
      <Header
        isEmployer
        maxWidth={CONTAINER_MAX_WIDTH}
        logo={<Logo style={{ cursor: "pointer" }} />}
      >
        <DropDown />
      </Header>
      <Box py={2.5}>
        <Container maxWidth={CONTAINER_MAX_WIDTH}>
          {back && (
            <Box mb={2.5}>
              <Back onClick={() => navigate(-1)} />
            </Box>
          )}
          <Grid alignItems="flex-start" columnSpacing={2} container>
            <Grid item md={3}>
              <Box display="flex" flexDirection="column" rowGap={1.5}>
                {leftPanel ? (
                  leftPanel
                ) : (
                  <>
                    <Sidebar currentActive={currentActive}/>
                  </>
                )}
              </Box>
            </Grid>
            <Grid item md={rightPanel ? 6 : 9}>
              {children}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
