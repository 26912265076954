import React from "react";
import { Card, InfoRow } from "@lipihipi/ui-components";
import { Box } from "@mui/material";

const BasicDetails = ({ profileDetail }: any) => {
  return (
    <Card disableAction heading="Basic Details">
      <Box display="flex" flexDirection="column" rowGap={2}>
        <InfoRow
          heading="Name"
          content={`${profileDetail?.user?.fname} ${profileDetail?.user?.lname}`}
        />

        <InfoRow
          heading="Mobile Number"
          content={profileDetail?.user?.contact_no ? profileDetail?.user?.contact_no :'-'}
        />

        <InfoRow heading="Email Address" content={profileDetail?.user?.email} />

        <InfoRow
          heading="Date of Birth"
          content={profileDetail?.dob ? profileDetail.dob : "-"}
        />

        <InfoRow
          heading="Blood Group"
          content={profileDetail?.blood_group ? profileDetail.blood_group : "-"}
        />

        <InfoRow
          heading="Address"
          content={`${profileDetail?.address_1} ${
            profileDetail?.address_2 ? profileDetail?.address_2 : ""
          }`}
        />

        <InfoRow
          heading="State/City"
          content={`${profileDetail?.state}, ${profileDetail?.city}`}
        />

        <InfoRow
          heading="Marital Status"
          content={
            profileDetail?.marital_status ? profileDetail?.marital_status : "-"
          }
        />

        <InfoRow
          heading="Gender"
          content={profileDetail?.gender ? profileDetail?.gender : ""}
        />
      </Box>
    </Card>
  );
};

export default BasicDetails;
