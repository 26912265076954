import React, { useEffect, useRef, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { SecondaryLayout } from "../../Components";
import { Card, Chips, Form, SelectBox, Text } from "@lipihipi/ui-components";
import { NotVerifiedIcon, VerifiedIcon } from "../../Icons";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { getShortlistedCandidates } from "../../Service/Service";
import Loader from "../../Components/Loader";
import { SHORTLIST_MENU_ITEM_KEYS } from "./constants";
import NotFitCandidateModal from "./NotFitCandidate";
import ScheduleInterview from "./ScheduleInterview";
import NoDataFound from "../../Shared/NoDataFound";
import handleToast, { success } from "../../utils/Toast";
import { useNavigate, useParams } from "react-router-dom";
import { replaceParamsInUrl } from "../../utils/common";
import { JOBSEEKERDETAIL } from "../../RouteConstant";

const MENU_ITEM = [
  { id: SHORTLIST_MENU_ITEM_KEYS.not_fit, label: "Not Fit" },
  {
    id: SHORTLIST_MENU_ITEM_KEYS.schedule_interview,
    label: "Schedule interview",
  },
  {
    id: SHORTLIST_MENU_ITEM_KEYS.remove,
    label: "Remove",
  },
];

const ShortlistedCandidates = () => {
  const [filterOptions, setFilterOptions] = useState([]);
  const [data, setData] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openinterview, setOpenInterview] = useState(false);
  const candidateData = useRef<Record<string, any> | undefined>();
  const [selectedValue, setSelectedValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const [refresh, setRefresh] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = (e: React.MouseEvent<HTMLLIElement>) => {
    switch ((e.target as HTMLLIElement).id) {
      case SHORTLIST_MENU_ITEM_KEYS.not_fit:
        setOpenConfirm(true);
        break;

      case SHORTLIST_MENU_ITEM_KEYS.remove:
        setOpenConfirm(true);
        break;

      case SHORTLIST_MENU_ITEM_KEYS.schedule_interview:
        setOpenInterview(true);
        break;
    }
    handleClose();
  };

  useEffect(() => {
    setLoading(true);
    getShortlistedCandidates(
      (data: Record<string, any>) => {
        setData(data?.candidateDetail);
        setFilterOptions(
          data?.jobTitle?.map(({ id, job_title }: any) => ({
            value: id,
            text: job_title,
          }))
        );
        setLoading(false);
      },
      () => setLoading(false),
      id || selectedValue || null
    );
  }, [selectedValue, id, refresh]);

  const onCardClick = (candidate: Record<string, any>) => {
    const userId = candidate?.user_id;
    const jobId = candidate?.job_id;
    navigate(
      replaceParamsInUrl(JOBSEEKERDETAIL, {
        jobId,
        userId,
      }),
      {
        state: { fromApp: true },
      }
    );
  };

  return (
    <>
      <SecondaryLayout currentActive={4}>
        <Box
          mb={1}
          display="flex"
          alignItems="end"
          justifyContent="space-between"
        >
          <Text variant="h3">
            Shortlisted Candidates ({data?.length || "0"})
          </Text>
          <div style={{ width: "40%" }}>
            {!id && (
              <Form>
                <SelectBox
                  value={selectedValue}
                  onChange={setSelectedValue}
                  values={"all"}
                  placeholder="Select a job"
                  disabled={!filterOptions.length}
                  items={filterOptions}
                  label=""
                />
              </Form>
            )}
          </div>
        </Box>
        <Box mt={2} display="flex" flexDirection="column" rowGap={1.5}>
          {loading ? (
            <Loader />
          ) : data.length ? (
            data.map((candidate: Record<string, any>, index) => {
              return (
                <Card
                  key={candidate?.id}
                  sx={{ cursor: "pointer" }}
                  px={2}
                  py={2}
                  disableHeader
                  onCardClick={() => onCardClick(candidate)}
                >
                  <Box display="flex">
                    <Box
                      pr={1}
                      flexGrow={1}
                      display="flex"
                      flexDirection="column"
                      rowGap={3}
                    >
                      <Box>
                        <Box
                          sx={{ mb: 1 }}
                          display="flex"
                          alignItems="center"
                          columnGap={1.5}
                        >
                          <Text variant="h3">
                            {candidate?.get_user?.fname || ""}{" "}
                            {candidate?.get_user?.lname || ""}
                          </Text>

                          <Box
                            display="flex"
                            justifyContent="center"
                            columnGap={0.5}
                            alignItems="center"
                          >
                            {parseInt(candidate?.get_user?.verified_user) ? (
                              <>
                                <VerifiedIcon size={18} />
                                <Text
                                  sx={{
                                    color: "#00C385",
                                    fontSize: 10,
                                    fontWeight: 500,
                                  }}
                                >
                                  ID Verified
                                </Text>
                              </>
                            ) : (
                              <>
                                <NotVerifiedIcon size={18} />
                                <Text
                                  sx={{
                                    color: "#EB5757",
                                    fontSize: 10,
                                    fontWeight: 500,
                                  }}
                                >
                                  Not Verified
                                </Text>
                              </>
                            )}
                          </Box>
                          {/* {candidate?.schedule_interview_status === 1 && (
                            <Box>
                              <Text
                                variant="caption"
                                sx={{ fontWeight: 500, color: "#929292" }}
                              >
                                Interview Scheduled
                              </Text>
                            </Box>
                          )} */}
                        </Box>
                        <Text
                          variant="h4"
                          sx={{ fontWeight: 600, color: "#595959" }}
                        >
                          {candidate?.get_user_profile?.skills}
                        </Text>
                      </Box>

                      <Box display="flex" flexDirection="column" rowGap={1.5}>
                        <Text
                          variant="h4"
                          sx={{ color: "#5A5A5A", fontWeight: 400 }}
                        >
                          {candidate?.get_user_profile?.profile_summary}
                        </Text>

                        <Box display="flex" alignItems="center" columnGap={2}>
                          <Text
                            variant="caption"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              columnGap: 1,
                            }}
                          >
                            <WorkHistoryIcon
                              sx={{ fontSize: 16, color: "#161458" }}
                            />
                            {candidate?.get_user_profile?.total_experience}
                            Years
                          </Text>
                          <Text variant="body2">
                            <FiberManualRecordIcon sx={{ fontSize: 8 }} />
                          </Text>
                          <Text
                            variant="caption"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              columnGap: 0.5,
                            }}
                          >
                            <CurrencyRupeeIcon
                              sx={{ fontSize: 16, color: "#161458" }}
                            />
                            {candidate?.get_user_profile?.current_salary} LPA
                          </Text>
                        </Box>
                        <Box>
                          <Text
                            variant="caption"
                            sx={{ fontWeight: 500, color: "#929292" }}
                          >
                            {candidate?.job?.job_title}
                          </Text>
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="flex-start"
                    >
                      {candidate?.schedule_interview_status === 1 && (
                        <Chips color="success" label='Interview Scheduled' />
                      )}
                      <IconButton
                        sx={{
                          color: "rgba(172, 172, 172, 1)",
                        }}
                        size="small"
                        id="basic-button"
                        aria-controls={open ? `basic-menu-${index}` : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={(e) => {
                          candidateData.current = candidate;
                          handleClick(e);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id={"basic-menu-" + index}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={(event: React.MouseEvent<HTMLDivElement>) => {
                          event.stopPropagation();
                          handleClose();
                        }}
                        elevation={2}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        onChange={console.log}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        {MENU_ITEM.map((item) => (
                          <MenuItem
                            id={item.id}
                            key={item.id}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleMenu(e);
                            }}
                          >
                            {item.label}
                          </MenuItem>
                        ))}
                      </Menu>
                    </Box>
                  </Box>
                </Card>
              );
            })
          ) : (
            <NoDataFound />
          )}
        </Box>
      </SecondaryLayout>

      <NotFitCandidateModal
        open={openConfirm}
        onSuccess={() => {
          handleToast("Removed Candidate successfully", success);
          setRefresh((p) => !p);
        }}
        handleClose={() => {
          setOpenConfirm(false);
        }}
        jobId={candidateData.current?.job_id}
        userId={candidateData.current?.user_id}
      />

      <ScheduleInterview
        open={openinterview}
        handleClose={() => setOpenInterview(false)}
        candidateData={candidateData.current}
        onSuccess={() => {
          handleToast("Interview scheduled successfully", success);
          setAnchorEl(null);
          setRefresh((p) => !p);
        }}
      />
    </>
  );
};

export default ShortlistedCandidates;
