import { PrimaryList, Card } from "@lipihipi/ui-components";
import certificate from "../../../Images/certificate.svg";

const ProjectDetails = ({ projects }: any) => {
  return (
    <Card disableAction heading="Projects / Assignments">
      {projects?.map((project: any) => {
        return (
          <>
            <PrimaryList
              isSecondary
              icon={<img src={certificate} alt="" />}
              chip={{
                color: "warning",
                label: "Under Process",
              }}
              data={{
                heading: project.project_name,
                primaryText: `Role - ${project.role}`,
                secondaryText: `Technology - ${project.technology}`,
              }}
              additionalText={
                project?.porject_desc
                  ? `Description - ${project?.porject_desc}`
                  : ""
              }
            />
          </>
        );
      })}
    </Card>
  );
};

export default ProjectDetails;
