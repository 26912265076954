import React, { useState } from "react";
import { AuthLayout } from "../../Components";
import GRAPHIC from "../../Images/builder.svg";
import { Button, InputBox, Text, Form } from "@lipihipi/ui-components";
import { Box } from "@mui/material";
import { register } from "../../Service/Service";
import { useNavigate } from "react-router-dom";
import { FORGOT_PASSWORD } from "../../RouteConstant";
import { Link } from "react-router-dom";
import { EmailSchema } from "./validationSchema";
import Login from "./Login";
import Register from "./Register";
import { userLogin } from "../../constant";

const onboardingData = {
  icon: GRAPHIC,
  primaryText: "You are a real Nation Builder",
  secondaryText: "Showing Job Seeker a new path to success",
};

const LoginPage = () => {
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState<any>(userLogin.Init);

  const onCheckMailSuccess = (res: any) => {
    console.log(res);
    if (res.response_code === 200 && res.user.email) {
      setIsLogin(userLogin.Login);
      // Render the login form
    } 
  };

  const OnCheckMailFailure = (err: any) => {
    setIsLogin(userLogin.Register);
  };

  return (
    <AuthLayout isLogin={true} data={onboardingData}>
      <Text
        sx={{
          mb: 3,
        }}
        variant="h2"
      >
        Please share your official email id and proceed!
      </Text>
      {isLogin === userLogin.Init ? (
        <Form
          initialValues={{ email: "" }}
          validationSchema={EmailSchema}
          onSubmit={(values: any) => {
            localStorage.setItem('email',values.email)
            return new Promise((rs, rj) => {
              register(
                values,
                (res: any) => {
                  onCheckMailSuccess(res);
                  rs("");
                },
                (err: any) => {
                  console.log(err)
                  OnCheckMailFailure(err);
                  rj("");
                }
              );
            });
          }}
          render={({
            handleChange,
            isValid,
            isSubmitting,
            submitForm,
          }: any) => {
            return (
              <>
                <InputBox
                  id="email"
                  name="email"
                  label="Corporate Email ID"
                  autoComplete="on"
                  placeholder="Enter here..."
                  fullWidth
                  required
                  onChange={handleChange}
                  sx={{ mb: 3 }}
                />
                <Box
                  display="flex"
                  justifyContent="end"
                  mb={3}
                  sx={{
                    "& a": {
                      color: "rgba(0, 0, 0, 0.87)",
                    },
                  }}
                >
                  <Link to={FORGOT_PASSWORD}>Forgot Password</Link>
                </Box>
                <Button
                  loading={isSubmitting}
                  disabled={!isValid}
                  onClick={isValid ? submitForm : () => {}}
                  shape="primary"
                  className="ml-3"
                  type="submit"
                  size="large"
                  fullWidth
                >
                  Login
                </Button>
              </>
            );
          }}
        />
      ) : isLogin === userLogin.Login ? (
        <Login />
      ) : (
        isLogin === userLogin.Register && <Register />
      )}
    </AuthLayout>
  );
};

export default LoginPage;
