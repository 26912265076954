import * as Yup from "yup";
import { JOB_POST_FORM_NAMES } from "../../Pages/Jobs/constants";

export const AddJobBasicSchema = Yup.object().shape({
  [JOB_POST_FORM_NAMES.job_title]: Yup.string()
    .required("Job title is required")
    .min(10, "Title must have at least 10 characters"),
  [JOB_POST_FORM_NAMES.job_type]: Yup.mixed().required("Job type is required"),
  [JOB_POST_FORM_NAMES.job_categories]: Yup.mixed().required(
    "Job categroies are required"
  ),
  [JOB_POST_FORM_NAMES.job_locations]: Yup.string().required(
    "Job locations are required"
  ),
  [JOB_POST_FORM_NAMES.total_openings]: Yup.number()
    .required("Openings count is required")
    .typeError("Opening count should be a number"),
  [JOB_POST_FORM_NAMES.minimum_experience]: Yup.number().required(
    "Minimum experience is required"
  ),
  [JOB_POST_FORM_NAMES.maximum_experience]: Yup.number()
    .min(
      Yup.ref(JOB_POST_FORM_NAMES.minimum_experience),
      "Maximum experience must be greater than minimum experience"
    )
    .required("Minimum experience is required"),

  [JOB_POST_FORM_NAMES.minimum_annual_salary]: Yup.number().required(
    "Minimum salary is required"
  ),
  [JOB_POST_FORM_NAMES.maximum_annual_salary]: Yup.number().min(
    Yup.ref(JOB_POST_FORM_NAMES.minimum_annual_salary),
    "Maximum salary must be greater than minimum salary"
  ).required("Maximum salary is required"),

  [JOB_POST_FORM_NAMES.key_skills]: Yup.string().required(
    "Key Skills are required"
  ),
});

export const AddJobDescriptionSchema = Yup.object().shape({
  [JOB_POST_FORM_NAMES.description]: Yup.string().required(
    "Description is required"
  ),
  [JOB_POST_FORM_NAMES.short_description]: Yup.string()
    .max(150, "Short Description must be at most 150 characters")
    .required("Short description is required"),
});
