import { SecondaryLayout } from "../../Components";
import { Box, IconButton } from "@mui/material";
import { Button, Card, CustomTable, Text } from "@lipihipi/ui-components";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import BlockIcon from "@mui/icons-material/Block";
import EditIcon from "@mui/icons-material/Edit";
import { Modal } from "../../Components/Modal";
import { useEffect, useState } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  changeStatusAchievement,
  deleteAchievement,
  getAchievementList,
} from "../../Service/Service";
import handleToast, { success } from "../../utils/Toast";
import AddModal from "./AddModal";

const Achievements = () => {
  const [open, setOpen] = useState<any>({ isOpen: false, id: null });
  const [block, setBlock] = useState(false);
  const [achievementData, setAchievementData] = useState([]);
  const [selectedId, setSelectedId] = useState("");

  const TableData = {
    heading: [
      { id: 0, label: "S.No" },
      { id: 1, label: "Title" },
      { id: 2, label: "Description" },
      { id: 3, label: "Status" },
      { id: 4, label: "Action" },
    ],
  };

  useEffect(() => {
    getAchievementList(onSuccess);
  }, [open]);

  const onDeleteHandle = (id: any) => {
    setBlock(true)
    setSelectedId(id);
  };

  const onDeleteSuccess = (res:any) => {
    setBlock(false)
    handleToast(res.message,success)
    getAchievementList(onSuccess);
  };

  const onBlockHandler = (id: any, status: any) => {
    let payload: any = {
      id: id,
      status: status === 1 ? 0 : 1,
    };
    changeStatusAchievement(payload, (res: any) => {
      getAchievementList(onSuccess);
      handleToast(res.message, success);
    });
  };

  const onSuccess = (data: any) => {
    const tableData = data?.data.map((item: any, index: any) => ({
      id: index + 1,
      Title: item.title,
      Description: item.description,
      status: item.status === 1 ? "Active" : "Inactive",
      action: (
        <>
          <IconButton size="small">
            <EditIcon
              sx={{ fontSize: 20 }}
              onClick={() => setOpen({ isOpen: true, id: item.id })}
            />
          </IconButton>
          <IconButton
            sx={{
              "&:hover": {
                backgroundColor: "#ffeded",
              },
            }}
            size="small"
            color="error"
            onClick={() => onBlockHandler(item.id, item.status)}
          >
            <BlockIcon sx={{ color: "#d32f2f", fontSize: 20 }} />
          </IconButton>
          <IconButton
            sx={{
              "&:hover": {
                backgroundColor: "#ffeded",
              },
            }}
            size="small"
            color="error"
            onClick={() => onDeleteHandle(item.id)}
          >
            <DeleteOutlineOutlinedIcon
              sx={{ color: "#d32f2f", fontSize: 20 }}
            />
          </IconButton>
        </>
      ),
    }));

    setAchievementData(tableData);
  };
  const onFailure = (data: any) => {
    console.log(data);
  };

  return (
    <>
      <SecondaryLayout currentActive={12}>
        <Box display="flex" flexDirection="column" rowGap={2}>
          <Card
            heading="Achievements"
            onClick={() => setOpen({ isOpen: true })}
            action={{ text: "Add Achievement" }}
          >
            <Box mx={-4}>
              <CustomTable data={achievementData} heading={TableData.heading} />
            </Box>
          </Card>
        </Box>
      </SecondaryLayout>

      <AddModal isOpen={open} setOpen={setOpen} />
      {block && (
        <Modal open={block} handleClose={() => setBlock(false)}>
          <Box py={3}>
            <ErrorOutlineIcon
              sx={{
                fontSize: 50,
                m: "0 auto",
                display: "block",
                color: "#161458",
              }}
            />

            <Box textAlign="center" mt={2}>
              <Text sx={{ color: "#161458", mb: 0.5 }} variant="h1">
                Delete
              </Text>

              <Text sx={{ color: "#161458" }}>
                Please ensure and then confirm!
              </Text>
            </Box>

            <Box mt={3} columnGap={1} display="flex" justifyContent="center">
              <Button
                onClick={() => setBlock(false)}
                size="large"
                variant="outlined"
                sx={{ px: 5 }}
              >
                No, Cancel!
              </Button>
              <Button
                size="large"
                sx={{ px: 5 }}
                onClick={() =>
                  deleteAchievement(selectedId, onDeleteSuccess, onFailure)
                }
              >
                Yes, Delete It!
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default Achievements;
