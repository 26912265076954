import React, { useEffect } from "react";
import { checkUserWallet } from "../../Service";
import { LoadingModal } from "../../Components";

type Props = {
  open: boolean;
  handleClose: () => void;
  onSuccess?: (res?: Record<string, any>) => void;
  onError?: (message?: string) => void;
  userId: string;
  jobId: string;
};

export const CheckWalletModal = ({
  open,
  handleClose,
  onSuccess,
  onError,
  userId,
  jobId,
}: Props) => {
  useEffect(() => {
    if (open) {
      checkUserWallet(
        { job_id: jobId, user_id: userId },
        (res: Record<string, any>) => {
          if (res.success) {
            onSuccess?.(res);
          } else {
            onError?.(res?.message);
          }
          handleClose();
        },
        () => {}
      );
    }
  }, [jobId, userId, open]);

  return (
    <>
      <LoadingModal open={open}>Please wait...</LoadingModal>
    </>
  );
};

export default CheckWalletModal;
