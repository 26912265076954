import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";

import { Box } from "@mui/material";

import { SecondaryLayout } from "../../../Components";
import { Button, Card, CustomTable } from "@lipihipi/ui-components";
import {
  getInterviewsDetails,
  getUserInterviewReport,
} from "../../../Service/Service";
import Loader from "../../../Components/Loader";
import InviteUser from "./InviteUser";
import handleToast, { success } from "../../../utils/Toast";
import { openLinkInNewTab } from "../../../utils/common";

const createRow = ({
  index,
  name,
  email,
  status,
  restRow,
  onReportClick,
}: {
  index: number;
  name: string;
  email: string;
  status: string;
  restRow: Record<string, any>;
  onReportClick?: (restRow: Record<string, any>) => void;
}) => ({
  index,
  name,
  email,
  status,
  result: (() => {
    if (status?.toLowerCase() === "success") {
      return (
        <Button variant="outlined" onClick={() => onReportClick?.(restRow)}>
          View Report
        </Button>
      );
    }

    return null;
  })(),
});
const TableData = {
  heading: [
    { id: "index", label: "S.No" },
    { id: "name", label: "Name" },
    { id: "email", label: "email" },
    { id: "status", label: "Status" },
    { id: "result", label: "Result" },
  ],
};

const InvitedUser = () => {
  const [data, setData] = useState([]);
  const [interview, setInterview] = useState<Record<string, any> | undefined>();
  const [loading, setLoading] = useState(true);
  const [inviteModal, setInviteModal] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const { id } = useParams();

  const onReportClick = useCallback(
    (restRow: Record<string, any>) => {
      const { user_id, result } = restRow;
      if (result) {
        openLinkInNewTab(result);
        return;
      }

      getUserInterviewReport(
        { slug: interview?.slug, user_id },
        (res: Record<string, any>) => {
          openLinkInNewTab(res.data);
        },
        () => {}
      );
    },
    [interview]
  );

  const tableData = useMemo(() => {
    return data?.map(
      ({ user, status, ...restRow }: Record<string, any>, index: number) => {
        return createRow({
          index: index + 1,
          name: `${user.fname} ${user.lname}`,
          email: user.email,
          status,
          restRow,
          onReportClick,
        });
      }
    );
  }, [data, onReportClick]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getInterviewsDetails(
        id,
        (data: Record<string, any>) => {
          setData(data?.data.users);
          setInterview(data?.data.interview);
          setLoading(false);
        },
        () => setLoading(false)
      );
    }
  }, [id, refreshData]);

  return (
    <>
      <SecondaryLayout currentActive={7}>
        <Card
          heading="Invited Users"
          action={{ text: "Invite User" }}
          onClick={() => setInviteModal(true)}
        >
          <Box mx={-4}>
            {loading ? (
              <Loader />
            ) : (
              <CustomTable data={tableData} heading={TableData.heading} />
            )}
          </Box>
        </Card>
      </SecondaryLayout>

      {!loading && (
        <InviteUser
          interviewDetails={interview}
          open={inviteModal}
          handleClose={() => setInviteModal(false)}
          onSuccess={(message) => {
            message && handleToast(message, success);
            setRefreshData((p) => !p);
          }}
        />
      )}
    </>
  );
};

export default InvitedUser;
