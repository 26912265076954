import { Back, Card, Text } from "@lipihipi/ui-components";
import { Box } from "@mui/material";
import {
  Settings,
  MenuBook,
  FiberManualRecord,
  LocationOn,
  MilitaryTech,
  CurrencyRupee,
} from "@mui/icons-material";
import BadgeIcon from "@mui/icons-material/Badge";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useNavigate } from "react-router-dom";

type props = {
  jobData: any;
  showBack?: Boolean;
  onClose?: any;
};
export const JobDetailCard = ({ jobData, showBack = true, onClose }: props) => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <>
      <Back onClick={showBack ? handleBack : onClose} />
      <Card px={2} py={2} disableHeader sx={{ mb: 1.5 }}>
        <Text variant="h3" sx={{ textTransform: "capitalize" }}>
          {jobData?.job_title?.toLowerCase()}
        </Text>

        <Box display="flex" alignItems="center" columnGap={2} mt={2}>
          <Text
            variant="caption"
            sx={{ display: "flex", alignItems: "center", columnGap: 1 }}
          >
            <MilitaryTech sx={{ fontSize: 16, color: "#161458" }} />
            {jobData?.key_skills}
          </Text>
        </Box>

        <Box mt={2} display="flex" alignItems="center" columnGap={2}>
          <Text
            variant="caption"
            sx={{ display: "flex", alignItems: "center", columnGap: 1 }}
          >
            <MenuBook sx={{ fontSize: 16, color: "#161458" }} />
            {jobData?.graduation_candidate}
            {jobData?.post_graduation_candidate &&
              `, ${jobData?.post_graduation_candidate}`}
            {jobData?.doctor_candidate && `, ${jobData?.doctor_candidate}`}
          </Text>

          <Text variant="body2">
            <FiberManualRecord sx={{ fontSize: 8 }} />
          </Text>

          <Text
            variant="caption"
            sx={{ display: "flex", alignItems: "center", columnGap: 1 }}
          >
            <Settings sx={{ fontSize: 16, color: "#161458" }} />
            {jobData?.job_categories}
          </Text>
        </Box>

        <Box mt={2} display="flex" alignItems="center" columnGap={2}>
          {jobData?.job_type?.value && (
            <>
              <Text
                variant="caption"
                sx={{ display: "flex", alignItems: "center", columnGap: 1 }}
              >
                <BadgeIcon sx={{ fontSize: 16, color: "#161458" }} />
                {jobData?.job_type?.value}
              </Text>

              <Text variant="body2">
                <FiberManualRecordIcon sx={{ fontSize: 8 }} />
              </Text>
            </>
          )}

          <Text
            variant="caption"
            sx={{ display: "flex", alignItems: "center", columnGap: 0.5 }}
          >
            <CurrencyRupee sx={{ fontSize: 16, color: "#161458" }} />
            {jobData?.min_annual_salary || jobData?.minimum_annual_salary}.
            {jobData?.min_annual_salary_thousand} -{" "}
            {jobData?.max_annual_salary || jobData?.maximum_annual_salary}.
            {jobData?.max_annual_salary_thousand} LPA
          </Text>

          <Text variant="body2">
            <FiberManualRecord sx={{ fontSize: 8 }} />
          </Text>

          <Text
            variant="caption"
            sx={{ display: "flex", alignItems: "center", columnGap: 1 }}
          >
            {jobData?.min_exp || jobData?.minimum_experience}-
            {jobData?.maximum_experience} years
          </Text>
        </Box>

        <Box display="flex" alignItems="center" columnGap={2} mt={2}>
          <Text
            variant="caption"
            sx={{ display: "flex", alignItems: "flex-start", columnGap: 0.5 }}
          >
            <LocationOn sx={{ fontSize: 16, color: "#161458" }} />
            {jobData?.job_locations}
          </Text>
        </Box>

        <Box display="flex" columnGap={2} alignItems="center" mt={3}>
          {/* <Button
              disabled={
                jobData?.action !== (0 || null) ||
                jobData?.action !== (0 || null)
              }
              onClick={handleJobApply}
            >
              {buttonText}
            </Button> */}

          {/* <IconButton
              onClick={() =>
                handleBookmark({
                  id: jobData?.id,
                  alreadyApplied: jobData?.save_for_later,
                })
              }
              color={jobData?.save_for_later === 1 ? "success" : "default"}
            >
              {jobData?.save_for_later === 1 ? (
                <BookmarkRemove sx={{ fontSize: 20, color: "#00C385" }} />
              ) : (
                <BookmarkBorder sx={{ fontSize: 20 }} />
              )}
            </IconButton> */}
        </Box>

        {jobData?.description && (
          <Box mt={3} display="flex" flexDirection="column" rowGap={2}>
            <Text variant="h3">Job Description</Text>

            <Box className="editor-data">
              <Box
                dangerouslySetInnerHTML={{
                  __html: jobData?.description,
                }}
              />
            </Box>
          </Box>
        )}

        {jobData?.must_have && (
          <Box mt={3} display="flex" flexDirection="column" rowGap={2}>
            <Text variant="h3">Must Have</Text>

            <Box className="editor-data">
              <Box
                dangerouslySetInnerHTML={{
                  __html: jobData?.must_have,
                }}
              />
            </Box>
          </Box>
        )}

        {jobData?.good_to_have && (
          <Box mt={3} display="flex" flexDirection="column" rowGap={2}>
            <Text variant="h3">Good To Have</Text>

            <Box className="editor-data">
              <Box
                dangerouslySetInnerHTML={{
                  __html: jobData?.good_to_have,
                }}
              />
            </Box>
          </Box>
        )}

        <Box display="flex" justifyContent="center" mt={5}>
          {/* <Button
              sx={{ px: 8 }}
              disabled={jobData?.action !== (0 || null)}
              onClick={handleJobApply}
            >
              {buttonText}
            </Button> */}
        </Box>
      </Card>
    </>
  );
};

export default JobDetailCard;
